// import { FlowTypes, FlowSubTypes } from "../../redux/types/modalTypes";

type Props = {
  onClose: () => void;
  // onOpen: (caller: FlowTypes, actionTaken: FlowTypes | FlowSubTypes) => void;
};
export default function PaymentRetryError(props: Props) {
  return (
    <div>
      <h1>Payment Retry Error Modal</h1>
      <button type="button" onClick={props.onClose}>
        Close
      </button>
    </div>
  );
}
