import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { Typography, Stack } from "@mui/material";

type Props = {
  error: ErrorPayload;
};
export default function LocalErrorMsg(props: Props) {
  return (
    <Stack
      sx={{
        display: props.error.status !== 444 ? "none" : "flex",
        minHeight: "50px",
      }}
      justifyContent="center"
    >
      <Typography variant="subtitle1" color="error" align="center">
        {props.error.message ||
          "Sorry, we're unable to process your request. Please check your network connection and try again later."}
      </Typography>
    </Stack>
  );
}
