import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { createAction } from "@reduxjs/toolkit";

const transferActions = {
  apiFetching: createAction(
    "dashboard/transfers/fetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction(
    "dashboard/transfers/apiError",
    (error: ErrorPayload) => ({
      payload: { error },
    }),
  ),
  apiSuccess: createAction(
    "dashboard/transfers/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  getAllTransfers: createAction("dashboard/transfers/getAllTransfers"),
  setAllTransfers: createAction(
    "dashboard/transfers/setAllTransfers",
    (transfers: any) => ({ payload: { transfers } }),
  ),
};
export default transferActions;
