import { Stack, Button, Typography, TextField, useTheme } from "@mui/material";
import StitchAsyncButton from "@pd/components/StitchAsyncButton";
import ColdLoginSubMsg from "../ColdLoginSubMsg";
import ErrorSubMsg from "../ErrorSubMsg";

type Props = {
  email: string;
  errorMsg: string;
  fetching: boolean;
  success: boolean;
  onEmailChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onSendEmail: () => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
};
export default function EmailLoginForm(props: Props) {
  const theme = useTheme();

  const handleHelpClick = () => {
    window.open("https://www.withstitch.com/contact", "_blank");
  };

  return (
    <Stack gap={1} alignItems="center" sx={{ height: "400px" }}>
      <Typography variant="h1">Log in</Typography>
      <Typography variant="body1">
        Enter your email to log into your Stitch account.
      </Typography>
      <TextField
        fullWidth
        type="string"
        label="Email address"
        variant="outlined"
        value={props.email}
        onChange={props.onEmailChange}
        onKeyDown={props.onKeyDown}
        sx={{ mt: 5 }}
      />
      {props.errorMsg ? <ErrorSubMsg errorMsg={props.errorMsg} /> : null}
      <Stack sx={{ width: "100%", mt: 5 }} gap={1}>
        <StitchAsyncButton
          buttonText="Submit"
          variant="contained"
          color="primary"
          logoColor="white"
          onClick={props.onSendEmail}
          success={props.success}
          loading={props.fetching}
          loadingSize="small"
          loadingPosition={{ top: -30, left: 0 }}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleHelpClick()}
          sx={{
            "&:hover": {
              backgroundColor: theme.palette.secondary.light,
            },
          }}
        >
          I need help
        </Button>
      </Stack>
      <ColdLoginSubMsg />
    </Stack>
  );
}
