import { createAction } from "@reduxjs/toolkit";
import { CreateTeamMemberType } from "@pd/layouts/MktplaceDashboard/pages/Settings/components/ManageTeam/types";
import { ErrorPayload } from "@pd/api/utils/safeFetch";

const crudTeamMembersActions = {
  apiFetching: createAction(
    "dashboard/crudTeamMember/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction(
    "dashboard/crudTeamMember/apiError",
    (error: ErrorPayload) => ({
      payload: { error },
    }),
  ),
  apiSuccess: createAction(
    "dashboard/crudTeamMember/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  createTeamMember: createAction(
    "dashboard/crudTeamMember/createTeamMember",
    (data: CreateTeamMemberType) => ({
      payload: { data },
    }),
  ),
  editTeamMember: createAction(
    "dashboard/crudTeamMember/editTeamMember",
    (data: CreateTeamMemberType, id: string) => ({
      payload: { data, id },
    }),
  ),
  deleteTeamMember: createAction(
    "dashboard/crudTeamMember/deleteTeamMember",
    (id: string) => ({
      payload: { id },
    }),
  ),
  resetTeamMemberForm: createAction(
    "dashboard/crudTeamMember/resetTeamMemberForm",
  ),
};

export default crudTeamMembersActions;
