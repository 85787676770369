import { LinkedAcctType } from "../../types";

export default function getAccountTexts(link: LinkedAcctType) {
  const title = link.institution.name;
  let subTitle = "";
  if (link.account.name && link.account.mask) {
    subTitle += `${link.account.name} (${link.account.mask})`;
  }
  let errDisplayMessage = link.error ? link.error.displayMessage : "";
  if (errDisplayMessage) {
    errDisplayMessage = errDisplayMessage.replace(/_/g, " ");
    errDisplayMessage =
      errDisplayMessage.charAt(0).toUpperCase() + errDisplayMessage.slice(1);
  }
  const errorMsg = link.error ? errDisplayMessage : "";

  let devTexts = "";
  if (process.env.STITCH_ENV === "dev") {
    if (link.error) {
      if (link.error.errorCode) {
        devTexts += ` | ${link.error.errorCode}`;
      }
      if (link.error.errorType) {
        devTexts += ` | ${link.error.errorType}`;
      }
    }
  }
  const result = { title, subTitle, errorMsg, devTexts };
  return result;
}
