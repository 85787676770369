import { createSelector } from "@reduxjs/toolkit";
import { PlaidLinkStateType, LinkedAcctType } from "../../types";

export const selectBankAccounts = createSelector(
  (state: { plaidLink: PlaidLinkStateType }) => state.plaidLink,
  (plaidLink) => plaidLink.linkedAccounts.data,
);

export type AcctSummariesType = {
  id: string;
  institutionName: string;
  accountName: string;
  mask: string;
};
export const selectPrimaryBankAccount = createSelector(
  (state: { plaidLink: PlaidLinkStateType }) =>
    state.plaidLink.linkedAccounts.data,
  (linkedAccounts): AcctSummariesType => {
    const summaries = linkedAccounts.accounts.map((la) => ({
      id: la.account.id,
      institutionName: la.institution.name,
      accountName: la.account.name,
      mask: la.account.mask,
    }));
    if (summaries.length === 0) {
      return {
        id: "",
        institutionName: "",
        accountName: "",
        mask: "",
      };
    }
    return summaries[0];
  },
);

export const selectLinkedAcctDetails = createSelector(
  (state: { plaidLink: PlaidLinkStateType }) => state.plaidLink,
  (plaidLink): LinkedAcctType => {
    if (plaidLink.linkedAccounts.data?.accounts?.length === 0) {
      return {
        account: {
          id: "",
          name: "",
          type: "",
          subtype: "",
          verificationStatus: "",
          mask: "",
        },
        institution: {
          name: "",
          id: "",
        },
        error: {
          displayMessage: "",
          errorCode: "",
          errorMessage: "",
          errorType: "",
        },
      };
    }
    return plaidLink.linkedAccounts.data.accounts[0];
  },
);

export const selectLinkedAccountId = createSelector(
  (state: { plaidLink: PlaidLinkStateType }) => state.plaidLink,
  (plaidLink) => plaidLink.linkedAccounts.data.linkAccountId,
);

export const selectTokensApiFetching = createSelector(
  (state: { plaidLink: PlaidLinkStateType }) => state.plaidLink,
  (plaidLink) => plaidLink.tokens.fetching,
);

export const selectTokensApiError = createSelector(
  (state: { plaidLink: PlaidLinkStateType }) => state.plaidLink,
  (plaidLink) => plaidLink.tokens.error,
);

export const selectLinkToken = createSelector(
  (state: { plaidLink: PlaidLinkStateType }) => state.plaidLink,
  (plaidLink) => plaidLink.tokens.data.linkToken,
);

export const selectPublicToken = createSelector(
  (state: { plaidLink: PlaidLinkStateType }) => state.plaidLink,
  (plaidLink) => plaidLink.tokens.data.publicToken,
);

export const selectAcctApiFetching = createSelector(
  (state: { plaidLink: PlaidLinkStateType }) => state.plaidLink,
  (plaidLink) => plaidLink.linkedAccounts.fetching,
);

export const selectAcctApiError = createSelector(
  (state: { plaidLink: PlaidLinkStateType }) => state.plaidLink,
  (plaidLink) => plaidLink.linkedAccounts.error,
);

export const selectAllLinkedAccts = createSelector(
  (state: { plaidLink: PlaidLinkStateType }) => state.plaidLink,
  (palidLink): LinkedAcctType[] => palidLink.linkedAccounts.data.accounts,
);
