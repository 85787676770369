import { createAction } from "@reduxjs/toolkit";
import { LinkedAcctType } from "@pd/components/PlaidLink/types";
import { BuyerPortalStateType } from "../types";
import {
  FlowSubTypes,
  FlowTypes,
  NextFlowTypes,
  NextFlowPropType,
} from "../types/modalTypes";

const actions = {
  fetching: createAction(
    "dashboard/fetching",
    (fetching: boolean, stateKey: keyof BuyerPortalStateType) => ({
      payload: { fetching, stateKey },
    }),
  ),
  apiError: createAction(
    "dashboard/apiError",
    (error: string, stateKey: keyof BuyerPortalStateType) => ({
      payload: { error, stateKey },
    }),
  ),
  transition: createAction(
    "dashboard/transition",
    (
      caller: FlowTypes,
      actionTaken: FlowTypes | FlowSubTypes,
      props?: NextFlowPropType,
    ) => ({
      payload: { caller, actionTaken, props },
    }),
  ),
  changeRoute: createAction("dashboard/changeRoute", (route: string) => ({
    payload: { route },
  })),
  open: createAction(
    "dashboard/open",
    (modal: NextFlowTypes, props?: NextFlowPropType) => ({
      payload: { modal, props },
    }),
  ),
  close: createAction("dashboard/close", () => ({
    payload: {},
  })),
  confirmPayment: createAction("dashboard/confirmPayment"),
  setPaymentMethod: createAction(
    "dashboard/setPaymentMethod",
    (paymentMethod: LinkedAcctType) => ({
      payload: { paymentMethod },
    }),
  ),
  setPaymentDate: createAction("dashboard/setPaymentDate", (date: string) => ({
    payload: { date },
  })),
};
export default actions;
