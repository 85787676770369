import { useNavigate, useParams } from "react-router";
import { Stack } from "@mui/material";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import authActions from "@pd/redux/actions/auth";
import StitchLoading from "@pd/components/StitchLoading";
import {
  selectAuthApiFetching,
  selectAuthApiError,
  selectLoggedIn,
} from "@pd/redux/selectors/auth";
import { getStitchApp } from "@pd/utils/appCheck";

export default function Authorize() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector(selectLoggedIn);
  const apiError = useAppSelector(selectAuthApiError);
  const apiFetching = useAppSelector(selectAuthApiFetching);
  const params = useParams<{ token: string }>();

  useEffect(() => {
    if (loggedIn) {
      navigate(`/${getStitchApp()}`, { replace: true });
    } else if (params.token) {
      dispatch(authActions.authorizeToken(params.token));
    }
  }, []);

  const errorMsg = apiError.message;
  useEffect(() => {
    if (!params.token) {
      navigate("/404", { replace: true });
    } else if (loggedIn) {
      navigate(`/${getStitchApp()}`, { replace: true });
    } else if (params.token || (!apiFetching && errorMsg)) {
      navigate("/auth/login/phone", { replace: true });
    }
  }, [apiFetching, loggedIn, errorMsg]);

  return (
    <Stack
      alignItems="flex-start"
      sx={{
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <StitchLoading />
    </Stack>
  );
}
