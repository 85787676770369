import { createAction } from "@reduxjs/toolkit";
import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { UploadReportType } from "@pd/layouts/MktplaceDashboard/types/csvUpload";

const csvUploadActions = {
  apiFetching: createAction(
    "dashboard/orders/csvUpload/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction(
    "dashboard/orders/csvUpload/apiError",
    (error: ErrorPayload) => ({
      payload: { error },
    }),
  ),
  apiSuccess: createAction(
    "dashboard/orders/csvUpload/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  uploadFile: createAction(
    "dashboard/orders/csvUpload/uploadFile",
    (fileData: string) => ({
      payload: { fileData },
    }),
  ),
  setFileName: createAction(
    "dashboard/orders/csvUpload/setFileName",
    (fileName: string) => ({
      payload: { fileName },
    }),
  ),
  setUploadReport: createAction(
    "dashboard/orders/csvUpload/setUploadReport",
    (report: UploadReportType, filename: string) => ({
      payload: { report, filename },
    }),
  ),
  reset: createAction("dashboard/orders/csvUpload/reset"),
};
export default csvUploadActions;
