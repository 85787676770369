import type { SagaMiddleware } from "redux-saga";
import { all, spawn } from "redux-saga/effects";
import authSaga from "@pd/redux/sagas/auth";
import buyerPortalModalSaga from "@pd/layouts/BuyerPortal/redux/sagas/modalManager";
import { isBuyerPortal, isMerchantDash } from "@pd/utils/appCheck";
import dashboardSaga from "../../layouts/MktplaceDashboard/redux/sagas/index";
import plaidLinkSagas from "../../components/PlaidLink/redux/sagas";

function getSagasByApp() {
  if (isBuyerPortal()) {
    return [authSaga, plaidLinkSagas, buyerPortalModalSaga];
  }
  if (isMerchantDash()) {
    return [authSaga, plaidLinkSagas, dashboardSaga];
  }
  return [];
}

const sagas = getSagasByApp();

function* rootSaga() {
  yield all(sagas.map((saga) => spawn(saga)));
}

export default function initSagas(sagaMiddleware: SagaMiddleware) {
  sagaMiddleware.run(rootSaga);
}
