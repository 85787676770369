import { useLoaderData, Outlet } from "react-router-dom";
import { Box } from "@mui/material";

import Header from "@pd/components/PortalHeader";
import AuthGuard from "@pd/components/AuthGuard";
import ModalContainer from "./modals/Container";

export default function BuyerPortal() {
  const magicLinkData = useLoaderData();
  console.log("magicLinkData: ", magicLinkData); // eslint-disable-line no-console, no-restricted-syntax

  return (
    <AuthGuard>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          minHeight: "100vh",
          backgroundColor: "#FFF",
        }}
      >
        <>
          <h1>Buyer Portal</h1>
          <Header />
          <Outlet />
          <ModalContainer />
        </>
      </Box>
    </AuthGuard>
  );
}
