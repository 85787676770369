import BuyerHome from "@pd/pages/BuyerPortal/Home";
import BuyerAuthorize from "@pd/pages/Auth/Authorize";
import BuyerInvoices from "@pd/pages/BuyerPortal/Invoices";
import BuyerSettings from "@pd/pages/BuyerPortal/Settings";
import BuyerAccount from "@pd/pages/BuyerPortal/Account";
import ErrorBoundary from "@pd/components/ErrorBoundary";
import Page404 from "@pd/pages/Page404";
import BuyerPortal from "./index";

export default [
  {
    path: "/portal",
    element: <BuyerPortal />,
    errorElement: <ErrorBoundary fallback />,
    children: [
      {
        path: "/portal",
        element: <BuyerHome />,
        errorElement: <ErrorBoundary fallback />,
      },
      {
        path: "authorize",
        element: <BuyerAuthorize />,
        errorElement: <ErrorBoundary fallback />,
      },
      {
        path: "invoices",
        element: <BuyerInvoices />,
        errorElement: <ErrorBoundary fallback />,
      },
      {
        path: "settings",
        element: <BuyerSettings />,
        errorElement: <ErrorBoundary fallback />,
      },
      {
        path: "account",
        element: <BuyerAccount />,
        errorElement: <ErrorBoundary fallback />,
      },
      {
        path: "*",
        element: <Page404 />,
        errorElement: <ErrorBoundary fallback />,
      },
    ],
  },
];
