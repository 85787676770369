import { ErrorReportType } from "@pd/layouts/MktplaceDashboard/types/csvUpload";
import { CSV_UPLOAD_TYPE } from "./constants";

export default class CsvUploadApiError extends Error {
  type: string;

  status: number;

  data: ErrorReportType;

  constructor(data: ErrorReportType) {
    super("CSV Upload Error: (422 status Code)");
    this.type = CSV_UPLOAD_TYPE;
    this.status = 422;
    this.data = data;
  }
}
