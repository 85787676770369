import { Link } from "react-router-dom";

// material-ui
import { Button, Stack, Typography } from "@mui/material";

// assets
import bgGrid from "@pd/assets/images/maintenance/bg-grid.png";
import svg500 from "@pd/assets/svgs/500.svg";
import isDevEnv from "@pd/utils/envCheck";

// ==============================|| ERROR 404 - MAIN ||============================== //

export default function Page500() {
  return (
    <Stack
      spacing={10}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundImage: `url(${bgGrid})`,
        backgroundSize: "cover",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Stack alignItems="center" justifyContent="center">
        <img
          src={svg500}
          alt="500-server-down"
          style={{
            maxWidth: "300px",
            width: "150px",
            height: "100%",
          }}
        />
      </Stack>
      <Stack gap={2} justifyContent="center" alignItems="center">
        <Typography
          color="primary"
          align="center"
          variant="h4"
          sx={{ fontFamily: "CircularBold" }}
        >
          Server Error
        </Typography>
        <Typography color="primary" align="center" variant="body1">
          Something went wrong on our end.
          <br />
          Try refreshing the page or trying again later.
        </Typography>
        {isDevEnv() ? (
          <Button component={Link} to="/debugger" variant="outlined">
            Debugger
          </Button>
        ) : null}
      </Stack>
    </Stack>
  );
}
