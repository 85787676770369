// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //

export default function Typography() {
  return {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "'CircularRegular', 'PublicSans', sans-serif",
        },
        gutterBottom: {
          marginBottom: 12,
        },
        // h1: {
        //   fontFamily: "'CircularBold', sans-serif",
        // },
        // h2: {
        //   fontFamily: "'CircularBold', sans-serif",
        // },
        // h3: {
        //   fontFamily: "'CircularBold', sans-serif",
        // },
        // h4: {
        //   fontFamily: "'CircularBold', sans-serif",
        // },
        // h5: {
        //   fontFamily: "'CircularBold', sans-serif",
        // },
        // body1: {
        //   fontFamily: "'CircularRegular', sans-serif",
        //   fontSize: 16,
        //   lineHeight: "20px",
        // },
        // body2: {
        //   fontFamily: "'CircularRegular', sans-serif",
        //   fontSize: 14,
        //   lineHeight: "16px",
        // },
      },
    },
  };
}
