import { AppStateType } from "@pd/redux/store/reducers";
import { BuyerPortalStateType } from "../types";
import { ModalStateType } from "../types/modalTypes";

export function selectVendorSlug(state: AppStateType) {
  if (hasPortal(state)) {
    return state.portal.merchantInfo.data?.logoSlug;
  }
  return null;
}

export function selectCurrentModal(state: AppStateType) {
  if (hasModals(state)) {
    return state.modals.activeModal;
  }
  return null;
}

export function selectCurrentModalProps(state: AppStateType) {
  if (hasModals(state)) {
    return state.modals.props;
  }
  return null;
}

export function selectPaymentDate(state: AppStateType) {
  if (hasModals(state)) {
    return state.modals.paymentDate;
  }
  return "";
}

function hasModals(
  state: AppStateType,
): state is AppStateType & { modals: ModalStateType } {
  return "modals" in state;
}

function hasPortal(
  state: AppStateType,
): state is AppStateType & { portal: BuyerPortalStateType } {
  return "portal" in state;
}
