export default function clearAllCookies() {
  const cookies = document.cookie.split("; ");

  cookies
    .filter((c) => {
      const cookieName = encodeURIComponent(c.split(";")[0].split("=")[0]);
      let env = process.env.STITCH_ENV || "";
      if (env === "dev") {
        env = "development";
      }
      return cookieName.includes(env.toUpperCase());
    })
    .forEach((c) => {
      const cookieName = encodeURIComponent(c.split(";")[0].split("=")[0]);
      const domainParts = window.location.hostname.split(".");

      while (domainParts.length > 0) {
        const domain = domainParts.join(".");
        const cookieBase = `${cookieName}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=${domain}; path=`;
        const pathParts = window.location.pathname.split("/");

        document.cookie = `${cookieBase}/`;

        pathParts.forEach((_, i) => {
          document.cookie = `${cookieBase}${pathParts
            .slice(0, i + 1)
            .join("/")}`;
        });

        domainParts.shift();
      }
    });
}
