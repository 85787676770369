import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { createAction } from "@reduxjs/toolkit";
import { ApiKeyDbType } from "@pd/redux/types/dbTypes";

const settingsActions = {
  apiFetching: createAction(
    "dashboard/settings/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction(
    "dashboard/settings/apiError",
    (error: ErrorPayload) => ({
      payload: { error },
    }),
  ),
  apiSuccess: createAction(
    "dashboard/settings/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  getApiKeys: createAction("dashboard/settings/getApiKeys"),
  setApiKeys: createAction(
    "dashboard/settings/setApiKeys",
    (keys: ApiKeyDbType[]) => ({
      payload: { keys },
    }),
  ),
};
export default settingsActions;
