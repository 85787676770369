import { useNavigate } from "react-router-dom";
import { persistApi } from "@pd/redux/store";
import { Box, Stack, Button, Typography } from "@mui/material";
import { isBuyerPortal } from "@pd/utils/appCheck";

export default function DevDashboard() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        minHeight: "100vh",
        backgroundColor: "#FFF",
      }}
    >
      <Stack justifyContent="start" gap={10}>
        <Stack gap={2} direction="row">
          <Typography variant="h1">Dev Dashboard</Typography>
          <Button
            color="info"
            variant="contained"
            onClick={() => navigate(isBuyerPortal() ? "/portal" : "/dashboard")}
          >
            Go To App
          </Button>
        </Stack>
        <Stack direction="row" gap={2}>
          <Typography variant="h2">Redux Store</Typography>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              persistApi.purge().then(() => {
                alert("Store purged. Refresh to see changes.");
              });
            }}
            sx={{ width: 100 }}
          >
            PURGE
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={() => {
              persistApi.pause();
              alert("Store purged. Refresh to see changes.");
            }}
            sx={{ width: 100 }}
          >
            PAUSE
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              persistApi.persist();
              alert("Store persisted. Refresh to see changes.");
            }}
            sx={{ width: 100 }}
          >
            PERSIST
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              persistApi.flush();
              alert("Store flushed. Refresh to see changes.");
            }}
            sx={{ width: 100 }}
          >
            FLUSH
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
