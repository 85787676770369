import tracer from "@pd/tracing";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";
import { LOCAL_API_ERROR } from "@pd/tracing/constants";

export default function createTracerMiddleware() {
  return (_: any) => (next: any) => (action: any) => {
    if (action.type && action.type.includes("apiError")) {
      const isLocalError = action.payload?.error?.status === MANUAL_ERROR_CODE;
      if (isLocalError) {
        const { payload } = action;
        tracer.captureException(payload.error, LOCAL_API_ERROR);
      }
    }
    return next(action);
  };
}
