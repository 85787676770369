import { createReducer } from "@reduxjs/toolkit";

import { createAsyncSlice } from "@pd/redux/types";
import { AllVendorsStateType } from "@pd/layouts/MktplaceDashboard/types/vendors";
import da from "../../actions";

export const initVendorState: AllVendorsStateType = createAsyncSlice({
  allVendors: [],
  filteredVendors: [],
  searchedVendors: [],
});

export default function createVendorsReducer() {
  return createReducer<AllVendorsStateType>(initVendorState, (builder) => {
    builder
      .addCase(da.vendors.all.apiError, onApiError)
      .addCase(da.vendors.all.apiFetching, onApiFetching)
      .addCase(da.vendors.all.apiSuccess, onApiSuccess)
      .addCase(da.vendors.all.setVendors, onSetVendors)
      .addCase(da.vendors.all.setFilteredVendors, onSetFilteredVendors)
      .addCase(da.vendors.all.setSearchedVendors, onSetSearchedVendors)
      .addCase(da.vendors.all.clearSearchedVendors, onClearSearchedVendors);
  });
}

export function onApiFetching(
  state: AllVendorsStateType,
  action: ReturnType<typeof da.vendors.all.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiSuccess(
  state: AllVendorsStateType,
  action: ReturnType<typeof da.vendors.all.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onApiError(
  state: AllVendorsStateType,
  action: ReturnType<typeof da.vendors.all.apiError>,
) {
  state.error = action.payload.error;
}

export function onSetVendors(
  state: AllVendorsStateType,
  action: ReturnType<typeof da.vendors.all.setVendors>,
) {
  state.data.allVendors = action.payload.vendors;
}

export function onSetFilteredVendors(
  state: AllVendorsStateType,
  action: ReturnType<typeof da.vendors.all.setFilteredVendors>,
) {
  state.data.filteredVendors = action.payload.vendors;
}

export function onSetSearchedVendors(
  state: AllVendorsStateType,
  action: ReturnType<typeof da.vendors.all.setSearchedVendors>,
) {
  state.data.searchedVendors = action.payload.searchedVendors;
}

export function onClearSearchedVendors(state: AllVendorsStateType) {
  state.data.searchedVendors = [];
}
