import { createReducer } from "@reduxjs/toolkit";
import modalActions from "../actions/modalActions";
import { ModalStateType } from "../types/modalTypes";

export const initialState = {
  fetching: false,
  error: "",
  data: {},
  activeModal: undefined,
  props: null,
  paymentMethod: null,
  paymentDate: "",
};

const modalReducer = createReducer<ModalStateType>(initialState, (builder) => {
  builder
    .addCase(modalActions.close, onClose)
    .addCase(modalActions.open, onOpen)
    .addCase(modalActions.fetching, onFetching)
    .addCase(modalActions.apiError, onApiError)
    .addCase(modalActions.setPaymentMethod, onSetPaymentMethod)
    .addCase(modalActions.setPaymentDate, onSetPaymentDate);
});
export default modalReducer;

function onOpen(
  state: ModalStateType,
  action: ReturnType<typeof modalActions.open>,
) {
  const { modal, props } = action.payload;
  state.activeModal = modal;
  if (props) {
    state.props = props;
  }
}

function onClose(state: ModalStateType) {
  state.activeModal = undefined;
  state.props = null;
}

function onFetching(
  state: ModalStateType,
  action: ReturnType<typeof modalActions.fetching>,
) {
  state.fetching = action.payload.fetching;
}

function onApiError(
  state: ModalStateType,
  action: ReturnType<typeof modalActions.apiError>,
) {
  state.fetching = false;
  state.error = action.payload.error;
}

function onSetPaymentMethod(
  state: ModalStateType,
  action: ReturnType<typeof modalActions.setPaymentMethod>,
) {
  state.paymentMethod = action.payload.paymentMethod;
}

function onSetPaymentDate(
  state: ModalStateType,
  action: ReturnType<typeof modalActions.setPaymentDate>,
) {
  state.paymentDate = action.payload.date;
}
