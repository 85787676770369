import { createReducer } from "@reduxjs/toolkit";
import { createAsyncSlice } from "@pd/redux/types";
import pla from "../actions";
import { PlaidTokenStateType } from "../../types";

export const initialState: PlaidTokenStateType = createAsyncSlice({
  linkToken: "",
  publicToken: "",
});

export default function plaidTokenReducer() {
  return createReducer<PlaidTokenStateType>(initialState, (builder) => {
    builder
      .addCase(pla.tokens.apiFetching, onApiFetching)
      .addCase(pla.tokens.apiSuccess, onApiSuccess)
      .addCase(pla.tokens.apiError, onApiError)
      .addCase(pla.tokens.setLinkToken, onSetLinkToken)
      .addCase(pla.tokens.clearTokens, onClearTokens)
      .addCase(pla.tokens.getLinkToken, onGetLinkToken);
  });
}

export function onApiFetching(
  state: PlaidTokenStateType,
  action: ReturnType<typeof pla.tokens.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiSuccess(
  state: PlaidTokenStateType,
  action: ReturnType<typeof pla.tokens.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onApiError(
  state: PlaidTokenStateType,
  action: ReturnType<typeof pla.tokens.apiError>,
) {
  state.error = action.payload.error;
}

export function onSetLinkToken(
  state: PlaidTokenStateType,
  action: ReturnType<typeof pla.tokens.setLinkToken>,
) {
  state.data.linkToken = action.payload.linkToken;
}

export function onClearTokens(state: PlaidTokenStateType) {
  state.data.publicToken = "";
  state.data.linkToken = "";
}

export function onGetLinkToken(state: PlaidTokenStateType) {
  state.data.linkToken = "";
}
