import { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import { MainRoutes } from "@pd/routes";
import StitchLoading from "@pd/components/StitchLoading";

function App() {
  const [router, setRouter] = useState<
    ReturnType<typeof createBrowserRouter> | undefined
  >();

  useEffect(() => {
    const setupRoutes = async () => {
      const routes = [await MainRoutes()];
      const rootRouter = createBrowserRouter(routes);
      setTimeout(() => {
        setRouter(rootRouter);
      }, 500);
    };

    setupRoutes();
  }, []);

  if (!router) {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack gap={50} alignItems="center">
          <StitchLoading size="large" />
        </Stack>
      </Box>
    );
  }
  return <RouterProvider router={router} />;
}

export default App;
