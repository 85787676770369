/* eslint-disable no-shadow */
export enum OrderStatus {
  created = "created",
  confirmed = "confirmed",
  voided = "voided",
  unknown = "unknown",
}

export enum OrderPayoutStatus {
  pending = "pending",
  failed = "failed",
  complete = "complete",
  voided = "voided",
  unknown = "unknown",
}

export enum OrderFinanceStatus {
  yes = "yes",
  no = "no",
}
