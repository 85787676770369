import { combineReducers } from "@reduxjs/toolkit";
import { PlaidLinkStateType } from "@pd/components/PlaidLink/types";
import createTokensReducer from "./tokens";
import createAccountsReducer from "./accounts";

const plaidReducer = combineReducers<PlaidLinkStateType>({
  tokens: createTokensReducer(),
  linkedAccounts: createAccountsReducer(),
});
export default plaidReducer;
