import { useState } from "react";
import {
  Modal,
  Button,
  ButtonBase,
  Fade,
  Stack,
  Backdrop,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@ant-design/icons";
import { FlowTypes, FlowSubTypes } from "../../redux/types/modalTypes";
import { modalStyles } from "../constants/styles";

type Props = {
  onClose: () => void;
  onOpen: (caller: FlowTypes, actionTaken: FlowTypes | FlowSubTypes) => void;
};
export default function PaymentConfirmed(props: Props) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const handleReviewAutoPay = () => {
    setOpen(false);
    props.onOpen(FlowTypes.paymentConfirmed, FlowTypes.autopayReview);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}
    >
      <Fade in>
        <Stack gap={2} sx={modalStyles}>
          <Stack justifyContent="center" direction="row">
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", flexGrow: 1, textAlign: "center" }}
            >
              Payment Confirmed
            </Typography>
            <ButtonBase onClick={handleClose}>
              <CloseOutlined />
            </ButtonBase>
          </Stack>
          <Typography variant="subtitle1">
            Tunrn on autopay for all outstanding invoices to avoid late and
            missed payments. Payments will be made per invoice at 9am PST on
            their due date
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleReviewAutoPay}
          >
            Turn On
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Not Now
          </Button>
        </Stack>
      </Fade>
    </Modal>
  );
}
