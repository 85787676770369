import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import createTracerMiddleware from "@pd/tracing/tracerMiddleware";

import createPersistor, {
  persistMiddleware,
  persistedReducer,
} from "./persist";
import initSagas from "../sagas";

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(persistMiddleware).concat(
      sagaMiddleware,
      createTracerMiddleware(),
    ),
});

export const { persistor, persistApi } = createPersistor(store);
initSagas(sagaMiddleware);

export type RootStateType = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootStateType> = useSelector;
export default store;
