import { useState } from "react";
import {
  Modal,
  ButtonBase,
  Fade,
  Stack,
  Backdrop,
  Typography,
} from "@mui/material";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import PlaidLinkByButton from "@pd/components/PlaidLink/LinkByButton";
import { modalStyles } from "../constants/styles";
import {
  FlowTypes,
  FlowSubTypes,
  NextFlowPropType,
} from "../../redux/types/modalTypes";

type Props = {
  onClose: () => void;
  onOpen: (
    caller: FlowTypes,
    actionTaken: FlowTypes | FlowSubTypes,
    props?: NextFlowPropType,
  ) => void;
  backProps: NextFlowPropType;
};
export default function EditPayMethod(props: Props) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    props.onClose();
    setOpen(false);
  };

  const handleBack = () => {
    props.onOpen(FlowTypes.editPayMethod, FlowSubTypes.back, props.backProps);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}
    >
      <Fade in>
        <Stack gap={2} sx={modalStyles}>
          <Stack justifyContent="space-between" direction="row">
            <ButtonBase onClick={handleBack}>
              <ArrowLeftOutlined />
            </ButtonBase>
            <Typography variant="body1">Choose Pay Method</Typography>
            <ButtonBase onClick={handleClose}>
              <CloseOutlined />
            </ButtonBase>
          </Stack>
          <Stack gap={2}>
            <Typography variant="subtitle1">Linked Accounts</Typography>
            <PlaidLinkByButton />
          </Stack>
        </Stack>
      </Fade>
    </Modal>
  );
}
