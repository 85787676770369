import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { createAction } from "@reduxjs/toolkit";

const pingPongActions = {
  apiFetching: createAction(
    "dashboard/pingPong/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction(
    "dashboard/pingPong/apiError",
    (error: ErrorPayload) => ({
      payload: { error },
    }),
  ),
  apiSuccess: createAction(
    "dashboard/pingPong/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  getPingCheck: createAction("dashboard/pingPong/getPingCheck"),
  setPingCheck: createAction(
    "dashboard/pingPong/setPingCheck",
    (status: string) => ({
      payload: { status },
    }),
  ),
};
export default pingPongActions;
