import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { VendorDbType, VendorsSearchDbType } from "@pd/redux/types/dbTypes";
import { createAction } from "@reduxjs/toolkit";

const allVendorActions = {
  apiFetching: createAction(
    "dashboard/vendors/all/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction(
    "dashboard/vendors/all/apiError",
    (error: ErrorPayload) => ({
      payload: { error },
    }),
  ),
  apiSuccess: createAction(
    "dashboard/vendors/all/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  getVendors: createAction("dashboard/vendors/all/getVendors"),
  getVendorById: createAction(
    "dashboard/vendors/all/getVendorById",
    (id: string) => ({
      payload: { id },
    }),
  ),
  setVendors: createAction(
    "dashboard/vendors/all/setVendors",
    (vendors: VendorDbType[]) => ({ payload: { vendors } }),
  ),
  confirmVendorPayout: createAction(
    "dashboard/vendors/all/confirmVendorPayout",
    (vendorId: string) => ({
      payload: { vendorId },
    }),
  ),
  setFilteredVendors: createAction(
    "dashboard/vendors/all/setFilteredVendors",
    (vendors: VendorDbType[]) => ({ payload: { vendors } }),
  ),
  searchVendors: createAction(
    "dashboard/vendor/all/searchVendors",
    (query: string) => ({
      payload: { query },
    }),
  ),
  setSearchedVendors: createAction(
    "dashboard/vendor/all/setSearchedVendors",
    (searchedVendors: VendorsSearchDbType[]) => ({
      payload: { searchedVendors },
    }),
  ),
  clearSearchedVendors: createAction(
    "dashboard/vendor/all/clearSearchedVendors",
  ),
};
export default allVendorActions;
