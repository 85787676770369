import { Stack } from "@mui/material";

export default function BuyerAccount() {
  return (
    <Stack
      alignItems="flex-start"
      sx={{
        mt: "100px",
        pl: "40px",
      }}
    >
      <h1>Buyer Account</h1>
    </Stack>
  );
}
