import safeFetch, { ErrorResType } from "@pd/api/utils/safeFetch";
import { endpoints } from "@pd/config/envSetup";
import { BankingAccountDbType } from "@pd/redux/types/dbTypes";

export type LinkTokenResType = { data: string } | ErrorResType;
export const fetchLinkToken = async (): Promise<LinkTokenResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/banking/link`,
    {
      method: "GET",
      credentials: "include",
    },
  );
  return response;
};

export type ExgPublicTokenResType = { data: null } | ErrorResType;
export const fetchExchangePublicToken = async (
  publicToken: string,
  accountIds: string[],
): Promise<ExgPublicTokenResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/banking/link`,
    {
      method: "POST",
      body: JSON.stringify({
        public_token: publicToken,
        account_ids: accountIds,
      }),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  return response;
};

export type BankAccountsResType =
  | { data: BankingAccountDbType[] }
  | ErrorResType;
export const fetchBankAccounts = async (): Promise<BankAccountsResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/banking/accounts`,
    {
      method: "GET",
      credentials: "include",
    },
  );
  return response;
};
