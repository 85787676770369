import { Typography, Stack } from "@mui/material";

type Props = {
  errorMsg: string;
};
export default function ErrorSubMsg(props: Props) {
  return (
    <Stack
      sx={{ width: "100%", mt: 1 }}
      gap={1}
      justifyContent="center"
      alignItems="center"
    >
      <Typography
        color="error"
        variant="body2"
        sx={{ textAlign: "center", width: "80%" }}
      >
        {props.errorMsg}
      </Typography>
    </Stack>
  );
}
