import { useAppDispatch, useAppSelector } from "@pd/redux/store";

import {
  selectCurrentModal,
  selectCurrentModalProps,
} from "../../redux/selectors";
import PaymentReview from "../PaymentReview";
import modalActions from "../../redux/actions/modalActions";
import {
  NextFlowPropType,
  FlowSubTypes,
  FlowTypes,
} from "../../redux/types/modalTypes";
import SelectPayMethod from "../SelectPayMethod";
import PlaidLinkError from "../PlaidLinkError";
import PaymentRelinkError from "../PaymentRelinkError";
import PaymentConfirmed from "../PaymentConfirmed";
import PaymentCalendar from "../PaymentCalendar";
import PaymentRetryError from "../PaymentRetryError";
import EditPayMethod from "../EditPayMethod";
import AutopayReview from "../AutopayReview";
import AutopayConfirmed from "../AutopayConfirmed";
import PlaidLinkContainer from "../PlaidLinkContainer";

export default function ContainerModal() {
  const dispatch = useAppDispatch();
  const currentModal = useAppSelector(selectCurrentModal);
  const currentProps = useAppSelector(selectCurrentModalProps);

  const closeModal = () => {
    dispatch(modalActions.close());
  };

  const openModal = (
    caller: FlowTypes,
    actionTaken: FlowTypes | FlowSubTypes,
    props?: NextFlowPropType,
  ) => {
    dispatch(modalActions.transition(caller, actionTaken, props));
  };

  const confirmPayment = () => {
    dispatch(modalActions.confirmPayment());
  };

  const renderModal = () => {
    switch (currentModal) {
      case FlowTypes.selectPayMethod:
        return <SelectPayMethod onClose={closeModal} onOpen={openModal} />;
      case FlowTypes.paymentReview:
        return (
          <PaymentReview
            onClose={closeModal}
            onOpen={openModal}
            onConfirmPayment={confirmPayment}
          />
        );
      case FlowTypes.plaidLinking:
        return <PlaidLinkContainer onClose={closeModal} onOpen={openModal} />;
      case FlowTypes.plaidError:
        return <PlaidLinkError onClose={closeModal} onOpen={openModal} />;
      case FlowTypes.paymentRelinkError:
        return <PaymentRelinkError onClose={closeModal} onOpen={openModal} />;
      case FlowTypes.paymentConfirmed:
        return <PaymentConfirmed onClose={closeModal} onOpen={openModal} />;
      case FlowTypes.paymentCalendar:
        return <PaymentCalendar onClose={closeModal} onOpen={openModal} />;
      case FlowTypes.paymentRetryError:
        return <PaymentRetryError onClose={closeModal} onOpen={openModal} />;
      case FlowTypes.editPayMethod:
        return (
          <EditPayMethod
            onClose={closeModal}
            onOpen={openModal}
            backProps={currentProps}
          />
        );
      case FlowTypes.autopayReview:
        return <AutopayReview onClose={closeModal} onOpen={openModal} />;
      case FlowTypes.autopayConfirmed:
        return <AutopayConfirmed onClose={closeModal} onOpen={openModal} />;
      default:
        return null;
    }
  };

  return <div>{renderModal()}</div>;
}
