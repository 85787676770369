import { createReducer } from "@reduxjs/toolkit";
import { isAsyncStateType, createAsyncSlice } from "@pd/redux/types";
import portalActions from "../actions";
import type { BuyerPortalStateType } from "../types";

export const initialState = {
  auth: createAsyncSlice({ loggedIn: false, linkExpired: false }),
  merchantInfo: createAsyncSlice({ logoSlug: "" }),
  buyerIdentity: createAsyncSlice({ id: "", name: "", email: "" }),
  invoices: createAsyncSlice([]),
  targetInvoice: createAsyncSlice({}),
  linkedAccounts: createAsyncSlice([]),
  autopayInfo: createAsyncSlice({}),
  termsInfo: createAsyncSlice({}),
  achWiretInfo: createAsyncSlice({}),
  payment: createAsyncSlice({}),
};

const buyerPortalReducer = createReducer<BuyerPortalStateType>(
  initialState,
  (builder) => {
    builder.addCase(portalActions.fetching, onFetching);
    builder.addCase(portalActions.apiError, onApiError);
  },
);
export default buyerPortalReducer;

function onFetching(
  state: BuyerPortalStateType,
  action: ReturnType<typeof portalActions.fetching>,
) {
  const stateProperty = state[action.payload.stateKey];
  if (isAsyncStateType(stateProperty)) {
    stateProperty.fetching = action.payload.fetching;
  }
}

function onApiError(
  state: BuyerPortalStateType,
  action: ReturnType<typeof portalActions.apiError>,
) {
  const stateProperty = state[action.payload.stateKey];
  if (isAsyncStateType(stateProperty)) {
    stateProperty.fetching = false;
    stateProperty.error = action.payload.error;
  }
}
