import safeFetch, { ErrorResType } from "@pd/api/utils/safeFetch";
import { endpoints } from "@pd/config/envSetup";
import { MerchantProfileDbType } from "@pd/redux/types/dbTypes";

export type GetMerchantProfileResType =
  | { data: MerchantProfileDbType }
  | ErrorResType;
export const fetchGetMerchantProfile =
  async (): Promise<GetMerchantProfileResType> => {
    const response = await safeFetch(
      `${endpoints.stitchBackend}/dashboard/profile`,
      {
        method: "GET",
        credentials: "include",
      },
    );
    return response;
  };
