import { createReducer } from "@reduxjs/toolkit";

import { createAsyncSlice } from "@pd/redux/types";
import { TransfersStateType } from "@pd/layouts/MktplaceDashboard/types/transfers";
import da from "../../actions";

export const initialTransfersState = createAsyncSlice({
  allTransfers: [],
});

export default function createTransfersReducer() {
  return createReducer<TransfersStateType>(initialTransfersState, (builder) => {
    builder
      .addCase(da.transfers.apiError, onApiError)
      .addCase(da.transfers.apiFetching, onApiFetching)
      .addCase(da.transfers.apiSuccess, onApiSuccess)
      .addCase(da.transfers.setAllTransfers, onSetAllTransfers);
  });
}

export function onApiFetching(
  state: TransfersStateType,
  action: ReturnType<typeof da.transfers.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: TransfersStateType,
  action: ReturnType<typeof da.transfers.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: TransfersStateType,
  action: ReturnType<typeof da.transfers.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onSetAllTransfers(
  state: TransfersStateType,
  action: ReturnType<typeof da.transfers.setAllTransfers>,
) {
  state.data.allTransfers = action.payload.transfers;
}
