import { ErrorPayload } from "@pd/api/utils/safeFetch";
import {
  VendorInfoDbType,
  VendorOrdersDbType,
  VendorPayoutsDbType,
  VendorUnpaidOrdersDbType,
} from "@pd/redux/types/dbTypes";
import { InviteInfoReqPayload } from "@pd/layouts/MktplaceDashboard/types/vendors";
import { createAction } from "@reduxjs/toolkit";

const crudVendorActions = {
  apiFetching: createAction(
    "dashboard/vendor/crud/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction(
    "dashboard/vendor/crud/apiError",
    (error: ErrorPayload) => ({
      payload: { error },
    }),
  ),
  apiSuccess: createAction(
    "dashboard/vendor/crud/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  getVendorById: createAction(
    "dashboard/vendor/crud/getVendorById",
    (id: string) => ({
      payload: { id },
    }),
  ),
  setVendorById: createAction(
    "dashboard/vendor/crud/setVendorApiData",
    (vendor: VendorInfoDbType) => ({
      payload: { vendor },
    }),
  ),
  clearVendorById: createAction("dashboard/vendor/crud/clearVendorById"),
  getPayoutsByVendorId: createAction(
    "dashboard/vendor/crud/getPayoutsByVendorId",
    (id: string) => ({
      payload: { id },
    }),
  ),
  setPayouts: createAction(
    "dashboard/vendor/crud/setPayouts",
    (payouts: VendorPayoutsDbType[]) => ({
      payload: { payouts },
    }),
  ),
  getOrdersByVendorId: createAction(
    "dashboard/vendor/crud/getOrdersByVendorId",
    (id: string) => ({
      payload: { id },
    }),
  ),
  setOrders: createAction(
    "dashboard/vendor/crud/setOrders",
    (orders: VendorOrdersDbType[]) => ({
      payload: { orders },
    }),
  ),
  setUnpaidOrders: createAction(
    "dashboard/vendor/crud/setUnpaidOrders",
    (unpaidOrders: VendorUnpaidOrdersDbType[]) => ({
      payload: { unpaidOrders },
    }),
  ),
  payoutVendor: createAction(
    "dashboard/vendor/crud/payoutVendor",
    (id: string) => ({
      payload: { id },
    }),
  ),
  sendInvite: createAction(
    "dashboard/vendor/crud/sendInvite",
    (info: InviteInfoReqPayload) => ({
      payload: { info },
    }),
  ),
  setInviteLink: createAction(
    "dashboard/vendor/crud/setInviteLink",
    (link: string) => ({
      payload: { link },
    }),
  ),
  sendLoginLink: createAction(
    "dashboard/vendor/crud/sendLoginLink",
    (id: string) => ({
      payload: { id },
    }),
  ),
};
export default crudVendorActions;
