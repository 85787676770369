import { createReducer } from "@reduxjs/toolkit";

import { createAsyncSlice } from "@pd/redux/types";
import { CrudVendorsStateType } from "@pd/layouts/MktplaceDashboard/types/vendors";
import da from "../../actions";

export const initVendorCrudState: CrudVendorsStateType = createAsyncSlice({
  vendorById: null,
  unpaidOrders: [],
  payoutsHistory: [],
  ordersHistory: [],
  inviteLink: "",
});

export default function createVendorsReducer() {
  return createReducer<CrudVendorsStateType>(initVendorCrudState, (builder) => {
    builder
      .addCase(da.vendors.crud.apiError, onApiError)
      .addCase(da.vendors.crud.apiFetching, onApiFetching)
      .addCase(da.vendors.crud.apiSuccess, onApiSuccess)
      .addCase(da.vendors.crud.setVendorById, onSetVendorById)
      .addCase(da.vendors.crud.setPayouts, onSetPayouts)
      .addCase(da.vendors.crud.setOrders, onSetOrders)
      .addCase(da.vendors.crud.setUnpaidOrders, onSetUnpaidOrders)
      .addCase(da.vendors.crud.setInviteLink, onSetInviteLink);
  });
}

export function onApiFetching(
  state: CrudVendorsStateType,
  action: ReturnType<typeof da.vendors.crud.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiSuccess(
  state: CrudVendorsStateType,
  action: ReturnType<typeof da.vendors.crud.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onApiError(
  state: CrudVendorsStateType,
  action: ReturnType<typeof da.vendors.crud.apiError>,
) {
  state.error = action.payload.error;
}

export function onSetVendorById(
  state: CrudVendorsStateType,
  action: ReturnType<typeof da.vendors.crud.setVendorById>,
) {
  state.data.vendorById = action.payload.vendor;
}

export function onSetPayouts(
  state: CrudVendorsStateType,
  action: ReturnType<typeof da.vendors.crud.setPayouts>,
) {
  state.data.payoutsHistory = action.payload.payouts;
}

export function onSetOrders(
  state: CrudVendorsStateType,
  action: ReturnType<typeof da.vendors.crud.setOrders>,
) {
  state.data.ordersHistory = action.payload.orders;
}

export function onSetUnpaidOrders(
  state: CrudVendorsStateType,
  action: ReturnType<typeof da.vendors.crud.setUnpaidOrders>,
) {
  state.data.unpaidOrders = action.payload.unpaidOrders;
}

export function onSetInviteLink(
  state: CrudVendorsStateType,
  action: ReturnType<typeof da.vendors.crud.setInviteLink>,
) {
  state.data.inviteLink = action.payload.link;
}
