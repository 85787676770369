import { useState } from "react";
import {
  Modal,
  Button,
  ButtonBase,
  Fade,
  Stack,
  Backdrop,
  Typography,
} from "@mui/material";
import {
  CloseOutlined,
  CalendarOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useAppSelector } from "@pd/redux/store";
import { PlaidLinkStateType } from "@pd/components/PlaidLink/types";
import getAccountTexts from "@pd/components/PlaidLink/utils/getAccountTexts";
import {
  FlowTypes,
  FlowSubTypes,
  NextFlowPropType,
} from "../../redux/types/modalTypes";
import { modalStyles } from "../constants/styles";

type Props = {
  onClose: () => void;
  onOpen: (
    caller: FlowTypes,
    actionTaken: FlowTypes | FlowSubTypes,
    props?: NextFlowPropType,
  ) => void;
};
export default function AutopayReview(props: Props) {
  const linkState: PlaidLinkStateType = useAppSelector(
    (state) => state.plaidLink,
  );
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const handleEditPayment = () => {
    setOpen(false);
    props.onOpen(FlowTypes.autopayReview, FlowTypes.editPayMethod, {
      back: FlowTypes.autopayReview,
    });
  };

  const handleConfirmAutopay = () => {
    setOpen(false);
    props.onOpen(FlowTypes.autopayReview, FlowSubTypes.next);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}
    >
      <Fade in>
        <Stack gap={2} sx={modalStyles}>
          <Stack justifyContent="center" direction="row">
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", flexGrow: 1, textAlign: "center" }}
            >
              Review Autopay
            </Typography>
            <ButtonBase onClick={handleClose}>
              <CloseOutlined />
            </ButtonBase>
          </Stack>
          <Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="subtitle1">Payment Date</Typography>
              <Stack direction="row" alignItems="center">
                <Stack gap={1}>
                  <Typography variant="subtitle1" sx={{ textAlign: "right" }}>
                    Autopay on due date
                  </Typography>
                  <Typography variant="subtitle2" sx={{ textAlign: "right" }}>
                    All outstanding invoices will be charged on their due date
                  </Typography>
                </Stack>
                <CalendarOutlined />
              </Stack>
            </Stack>
            {linkState.linkedAccounts.data.map((link) => {
              const {
                title,
                subTitle,
                // errorMsg,
                // devTexts
              } = getAccountTexts(link);
              return (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  key={link.account.id}
                >
                  <Typography variant="subtitle1">Payment Method</Typography>
                  <Stack direction="row" alignItems="center">
                    <Stack gap={1}>
                      <Typography
                        variant="subtitle1"
                        sx={{ textAlign: "right" }}
                      >
                        {title}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{ textAlign: "right" }}
                      >
                        {subTitle}
                      </Typography>
                    </Stack>
                    <ButtonBase onClick={handleEditPayment}>
                      <EditOutlined />
                    </ButtonBase>
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmAutopay}
          >
            Confirm
          </Button>
        </Stack>
      </Fade>
    </Modal>
  );
}
