// import { useCallback, useEffect } from "react";
import { Typography } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import { useAppSelector } from "@pd/redux/store";
// import { openSnackbar } from "@pd/mantis/api/snackbar";
// import { SnackbarProps } from "@pd/mantis/types/snackbar";
// import pla from "./redux/actions";
import { PlaidLinkStateType, isPlaidLinkError } from "./types";
import RetryErrorMsg from "./components/RetryErrorMsg";
import usePlaid from "./hooks/usePlaid";

type PropTypes = {
  onClose: () => void;
  onLinkError: (error?: string) => void;
  // onShowPaymentReview: () => void;
  // onRelinkError: () => void;
};
function PlaidLinkModal(props: PropTypes) {
  // const dispatch = useAppDispatch();
  // // TODO: Refactor this to cherry-pick each part of state.
  const linkState: PlaidLinkStateType = useAppSelector(
    (state) => state.plaidLink,
  );

  // const { publicToken } = linkState.tokens.data;

  // TODO: Figure out how to show errors within the Plaid Modal.
  // const { error } = linkState.tokens;
  // useEffect(() => {
  //   if (!error) return;
  //   if (process.env.STITCH_ENV === "dev") {
  //     openSnackbar({
  //       open: true,
  //       message: error,
  //       anchorOrigin: {
  //         vertical: "top",
  //         horizontal: "right",
  //       },
  //       variant: "alert",
  //       alert: {
  //         color: "error",
  //       },
  //       close: true,
  //     } as SnackbarProps);
  //   }
  // }, [error]);

  const { startPlaidLink, plaidIsReady } = usePlaid();

  if (
    linkState.linkedAccounts.fetching ||
    linkState.tokens.fetching ||
    !plaidIsReady
  ) {
    return <LoadingOutlined />;
  }

  if (linkState.tokens.error) {
    if (isPlaidLinkError(linkState.tokens.error)) {
      console.error(linkState.tokens.error);
      props.onLinkError(linkState.tokens.error.display_message);
    }

    return (
      <Typography color="error">
        Could not connect to Plaid. Please try again later.
      </Typography>
    );
  }

  startPlaidLink({ retry: false, accountId: "" });

  if (linkState.linkedAccounts.error) {
    return (
      <RetryErrorMsg onLinkAccount={startPlaidLink} onClose={props.onClose} />
    );
  }

  return (
    <Typography variant="body1">
      Something went wrong. Please try again later.
    </Typography>
  );
}

export default PlaidLinkModal;
