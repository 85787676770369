import { createReducer } from "@reduxjs/toolkit";
import vendorRouteActions from "@pd/layouts/MktplaceDashboard/redux/actions/routes";
import { RouteStateType } from "@pd/layouts/MktplaceDashboard/types/routes";

const initialRouteState: RouteStateType = {
  nextRoute: "",
};

export default function createRouteReducer() {
  return createReducer<RouteStateType>(initialRouteState, (builder) => {
    builder
      .addCase(vendorRouteActions.redirect, onRedirect)
      .addCase(vendorRouteActions.clearNextRoute, onClearNextRoute);
  });
}

export function onRedirect(
  state: RouteStateType,
  action: ReturnType<typeof vendorRouteActions.redirect>,
) {
  state.nextRoute = action.payload.route;
}

export function onClearNextRoute(state: RouteStateType) {
  state.nextRoute = "";
}
