import ExpiredTokenApiError from "./expiredTokenError";
import ServerApiError from "./serverApiError";
import UnauthorizedApiError from "./unauthorizedApiError";
import NonCriticalApiError from "./nonCriticalApiError";
import CsvUploadApiError from "./csvUploadError";

export default {
  ExpiredTokenApiError,
  ServerApiError,
  UnauthorizedApiError,
  NonCriticalApiError,
  CsvUploadApiError,
};

export type ApiError =
  | ExpiredTokenApiError
  | ServerApiError
  | NonCriticalApiError
  | UnauthorizedApiError
  | CsvUploadApiError;
