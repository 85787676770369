import { createReducer } from "@reduxjs/toolkit";
import { createAsyncSlice } from "@pd/redux/types";
import { TableStateType } from "@pd/layouts/MktplaceDashboard/types/tables";
import da from "../../actions";

const initTableState = {
  dataSrc: undefined,
  miscFilter: {
    type: "",
    value: "",
  },
  dateFilter: {
    startDate: "",
    endDate: "",
  },
  search: {
    value: "",
  },
  pagination: {
    total: 0,
    limit: 100,
    offset: 0,
    sortDir: "",
    sortKey: "",
    sortEnabled: true,
  },
};
export const tableState = createAsyncSlice({ ...initTableState });

export default function createTableReducer() {
  return createReducer<TableStateType>(tableState, (builder) => {
    builder
      .addCase(da.table.apiFetching, onApiFetching)
      .addCase(da.table.apiError, onApiError)
      .addCase(da.table.apiSuccess, onApiSuccess)
      .addCase(da.table.setTableMiscFilter, onSetTableMiscFilter)
      .addCase(da.table.setTableDateFilter, onSetTableDateFilter)
      .addCase(da.table.setTableSearchFilter, onSetTableSearchFilter)
      .addCase(da.table.setTableDataSrc, onSetTableDataSrc)
      .addCase(da.table.clearTableFilters, onClearTableFilters)
      .addCase(da.table.setTablePagination, onSetTablePagination)
      .addCase(da.table.setSortDir, onSetSortDir)
      .addCase(da.table.setSortKey, onSetSortKey)
      .addCase(da.table.setSortEnabled, onSetSortEnabled);
  });
}

export function onApiFetching(
  state: TableStateType,
  action: ReturnType<typeof da.table.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: TableStateType,
  action: ReturnType<typeof da.table.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: TableStateType,
  action: ReturnType<typeof da.table.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onSetTableMiscFilter(
  state: TableStateType,
  action: ReturnType<typeof da.table.setTableMiscFilter>,
) {
  state.data.miscFilter = action.payload;
}

export function onSetTableDateFilter(
  state: TableStateType,
  action: ReturnType<typeof da.table.setTableDateFilter>,
) {
  state.data.dateFilter.startDate = action.payload.startDate;
  state.data.dateFilter.endDate = action.payload.endDate;
}

export function onSetTableSearchFilter(
  state: TableStateType,
  action: ReturnType<typeof da.table.setTableSearchFilter>,
) {
  state.data.search = action.payload;
}

export function onSetTableDataSrc(
  state: TableStateType,
  action: ReturnType<typeof da.table.setTableDataSrc>,
) {
  state.data.dataSrc = action.payload.dataSrc;
}

export function onClearTableFilters(state: TableStateType) {
  state.data = { ...initTableState };
}

export function onSetTablePagination(
  state: TableStateType,
  action: ReturnType<typeof da.table.setTablePagination>,
) {
  state.data.pagination = {
    ...state.data.pagination,
    ...action.payload,
  };
}

export function onSetSortDir(
  state: TableStateType,
  action: ReturnType<typeof da.table.setSortDir>,
) {
  state.data.pagination.sortDir = action.payload.sortDir;
}

export function onSetSortKey(
  state: TableStateType,
  action: ReturnType<typeof da.table.setSortKey>,
) {
  state.data.pagination.sortKey = action.payload.sortKey;
}

export function onSetSortEnabled(
  state: TableStateType,
  action: ReturnType<typeof da.table.setSortEnabled>,
) {
  state.data.pagination.sortEnabled = action.payload.sortEnabled;
}
