import { createReducer } from "@reduxjs/toolkit";

import type { CrudBuyerStateType } from "@pd/layouts/MktplaceDashboard/types/crudBuyer";
import { createAsyncSlice } from "@pd/redux/types";
import da from "../../actions";

export const initCrudBuyerState = createAsyncSlice({
  api: null,
  ui: null,
  // buyerById: null, // currently 404s
});

const uiBuyerTemplate = {
  companyName: "",
  extId: "",
  phone: "",
  email: "",
  taxId: "",
  firstName: "",
  lastName: "",
  address: {
    line1: "",
    line2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  },
};

export default function createCrudBuyerReducer() {
  return createReducer<CrudBuyerStateType>(initCrudBuyerState, (builder) => {
    builder
      .addCase(da.buyers.crud.apiFetching, onApiFetching)
      .addCase(da.buyers.crud.apiError, onApiError)
      .addCase(da.buyers.crud.apiSuccess, onApiSuccess)
      .addCase(da.buyers.crud.setBuyerApiData, onSetBuyerApiData)
      .addCase(da.buyers.crud.setBuyerUiData, onSetBuyerUiData)
      .addCase(da.buyers.crud.clearUpsertBuyer, onClearUpsertBuyer);
    // TODO: Uncomment when there's a getBuyerByIdAndMerchantId endpoint
    // .addCase(da.buyers.crud.setBuyerById, onSetBuyerById);
  });
}

export function onApiFetching(
  state: CrudBuyerStateType,
  action: ReturnType<typeof da.buyers.crud.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: CrudBuyerStateType,
  action: ReturnType<typeof da.buyers.crud.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: CrudBuyerStateType,
  action: ReturnType<typeof da.buyers.crud.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onSetBuyerApiData(
  state: CrudBuyerStateType,
  action: ReturnType<typeof da.buyers.crud.setBuyerApiData>,
) {
  state.data.api = action.payload.buyer;
  if (action.payload.buyer) {
    state.data.ui = {
      id: action.payload.buyer.id,
      companyName: action.payload.buyer.companyName,
      extId: action.payload.buyer.extId,
      firstName: action.payload.buyer.firstName,
      lastName: action.payload.buyer.lastName,
      phone: action.payload.buyer.phone,
      email: action.payload.buyer.email,
      taxId: action.payload.buyer.taxId,
      address: {
        line1: action.payload.buyer.address.line1,
        line2: action.payload.buyer.address.line2,
        city: action.payload.buyer.address.city,
        state: action.payload.buyer.address.state,
        postalCode: action.payload.buyer.address.postalCode,
        country: action.payload.buyer.address.country,
      },
    };
  }
}

export function onSetBuyerUiData(
  state: CrudBuyerStateType,
  action: ReturnType<typeof da.buyers.crud.setBuyerUiData>,
) {
  if (!state.data.ui) {
    state.data.ui = {
      ...uiBuyerTemplate,
      [action.payload.key]: action.payload.value,
    };
  } else if (action.payload.key in state.data.ui) {
    state.data.ui = {
      ...state.data.ui,
      [action.payload.key]: action.payload.value,
    };
  }
}

export function onClearUpsertBuyer(state: CrudBuyerStateType) {
  state.data.api = null;
  state.data.ui = null;
  state.error = { status: 0, message: "" };
  state.fetching = false;
  state.success = false;
}

// TODO: Uncomment when there's a getBuyerByIdAndMerchantId endpoint
// export function onSetBuyerById(
//   state: CrudBuyerStateType,
//   action: ReturnType<typeof da.buyers.crud.setBuyerById>,
// ) {
//   state.data.buyerById = action.payload.buyer;
// }
