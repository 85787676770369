import { NON_CRITICAL_ERROR_TYPE } from "./constants";

export default class NonCriticalApiError extends Error {
  type: string;

  status: number;

  constructor(m?: string) {
    super(m || "Non critical error: (300 status Code)");
    this.type = NON_CRITICAL_ERROR_TYPE;
    this.status = 300;
  }
}
