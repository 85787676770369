import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { SkeletonTheme } from "react-loading-skeleton";
import ReactDOM from "react-dom/client";
import store from "@pd/redux/store";
import App from "@pd/App/App";
import ScrollToTop from "@pd/components/providers/ScrollToTop";
import ThemeCustomization from "@pd/mantis/themes/index";
import ErrorBoundary from "@pd/components/ErrorBoundary/index";
import "./index.css";

console.log("Clearing local Storage: ", localStorage.clear()); // eslint-disable-line no-restricted-syntax
const root = document.getElementById("root")!;
root.dataset.commitHash = process.env.__COMMIT_HASH__ || "local";
ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <ThemeCustomization>
      <SkeletonTheme
        baseColor="#00000"
        highlightColor="#DADADA"
        borderRadius="0.5rem"
        duration={4}
      >
        <ReduxProvider store={store}>
          <ScrollToTop>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </ScrollToTop>
        </ReduxProvider>
      </SkeletonTheme>
    </ThemeCustomization>
  </React.StrictMode>,
);
