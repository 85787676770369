import { createSelector } from "@reduxjs/toolkit";
import { AppStateType } from "@pd/redux/store/reducers";
import { TeamMemberDbType } from "@pd/redux/types/dbTypes";
import { isDashboardState } from "@pd/layouts/MktplaceDashboard/types";

export const selectAllTeamMembers = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): TeamMemberDbType[] => {
    if (isDashboardState(state)) {
      const data = state.dashboard.teams.all.data.team;
      if (data) {
        return data;
      }
    }
    return [];
  },
);

export const selectAllTeamMembersApiFetching = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType) => {
    if (isDashboardState(state)) {
      return state.dashboard.teams.all.fetching;
    }
    return false;
  },
);

export const selectAllTeamMembersApiSuccess = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType) => {
    if (isDashboardState(state)) {
      return state.dashboard.teams.all.success;
    }
    return false;
  },
);

export const selectAllTeamMembersApiError = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType) => {
    if (isDashboardState(state)) {
      return state.dashboard.teams.all.error;
    }
    return { message: "", status: 0 };
  },
);

export const selectCrudTeamMembersApiFetching = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType) => {
    if (isDashboardState(state)) {
      const result = state.dashboard.teams.crud.fetching;
      return result;
    }
    return false;
  },
);

export const selectCrudTeamMembersApiSuccess = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType) => {
    if (isDashboardState(state)) {
      const result = state.dashboard.teams.crud.success;
      return result;
    }
    return false;
  },
);

export const selectCrudTeamMembersApiError = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType) => {
    if (isDashboardState(state)) {
      return state.dashboard.teams.crud.error;
    }
    return { message: "", status: 0 };
  },
);
