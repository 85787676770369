import { useEffect } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  redirect,
  Params,
} from "react-router-dom";
import { isTestEnv } from "@pd/utils/envCheck";
import Authorize from "@pd/pages/Auth/Authorize";
import AuthLogin from "@pd/pages/Auth";
import AuthEmailLogin from "@pd/pages/Auth/EmailLogin";
import AuthPhoneLogin from "@pd/pages/Auth/PhoneLogin";
import AuthLinkExpired from "@pd/pages/Auth/LinkExpired";
import routes404 from "@pd/pages/Page404/routes";
import routes500 from "@pd/pages/Page500/routes";
import { getStitchApp } from "@pd/utils/appCheck";
import { useAppSelector } from "@pd/redux/store";
import { selectLoggedIn } from "@pd/redux/selectors/auth";
import ErrorBoundary from "@pd/components/ErrorBoundary";

async function loadChildren() {
  let children = [
    ...routes404,
    ...routes500,
    {
      path: "/launch/:token",
      loader: async ({
        params,
      }: {
        params: Params<string>;
      }): Promise<string> => {
        if (!params.token) {
          redirect("/404");
          return "";
        }
        return params.token;
      },
      element: <Authorize />,
      errorElement: <ErrorBoundary fallback />,
    },
    {
      path: "/auth",
      element: <Outlet />,
      children: [
        {
          path: "login",
          element: <AuthLogin />,
          errorElement: <ErrorBoundary fallback />,
          children: [
            {
              path: "email",
              element: <AuthEmailLogin />,
              errorElement: <ErrorBoundary fallback />,
            },
            {
              path: "phone",
              element: <AuthPhoneLogin />,
              errorElement: <ErrorBoundary fallback />,
            },
          ],
        },
        {
          path: "link-expired",
          element: <AuthLinkExpired />,
          errorElement: <ErrorBoundary fallback />,
        },
      ],
    },
  ];
  if (process.env.STITCH_APP === "dashboard") {
    const dashboardRoutes = await import(
      "@pd/layouts/MktplaceDashboard/routes"
    );
    children = children.concat(dashboardRoutes.default);
  } else if (process.env.STITCH_APP === "portal") {
    const buyerRoutes = await import("@pd/layouts/BuyerPortal/routes");
    children = children.concat(buyerRoutes.default);
  }
  if (isTestEnv()) {
    const devDashboard = await import("@pd/pages/DebuggerDash");
    const DevDashboard = devDashboard.default;
    children.push({
      path: "/debugger",
      element: <DevDashboard />,
    });
  }
  return children;
}

const MainRoutes = async () => ({
  path: "/",
  element: <MainRoute />,
  errorElement: routes404[0].element,
  children: await loadChildren(),
});
export default MainRoutes;

function MainRoute() {
  const loggedIn: boolean = useAppSelector(selectLoggedIn);
  const location = useLocation();
  const navigate = useNavigate();

  const { pathname } = location;
  useEffect(() => {
    if (pathname === "/") {
      if (!loggedIn) {
        navigate("/auth/login/email", { replace: true });
      } else {
        navigate(`/${getStitchApp()}`, { replace: true });
      }
    }
  }, [pathname, navigate]);
  return <Outlet />;
}
