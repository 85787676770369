import { Stack, Typography, Button, useTheme } from "@mui/material";
import { LinkedAcctType } from "@pd/components/PlaidLink/types";
import StitchLoading from "@pd/components/StitchLoading";
import MiscColors from "@pd/mantis/themes/miscColors";
import getAccountStatus from "../../../utils/getAccountStatus";

const miscColors = MiscColors();
type Props = {
  plaidIsReady: boolean;
  account: LinkedAcctType;
  tokenFetching: boolean;
  onLinkAccount: (params: { retry: boolean; accountId: string }) => void;
};
export default function PlaidAcctStatusBadge(props: Props) {
  const theme = useTheme();
  const { text, color, showRetry } = getAccountStatus(props.account, theme);

  if (props.tokenFetching) {
    return (
      <Stack
        style={{ width: "100px" }}
        alignItems="center"
        justifyContent="center"
      >
        <StitchLoading size="small" position={{ top: -31 }} />
      </Stack>
    );
  }
  if (showRetry) {
    return (
      <Button
        variant="text"
        sx={{ color: miscColors.blueText, fontSize: "16px" }}
        onClick={() =>
          props.onLinkAccount({
            retry: true,
            accountId: props.account.account.id,
          })
        }
        disabled={!props.plaidIsReady}
      >
        Retry
      </Button>
    );
  }
  return (
    <Stack direction="row" alignItems="center">
      <div
        style={{
          borderRadius: 50,
          width: 5,
          height: 5,
          border: `5px solid ${
            props.account.error?.errorCode ? theme.palette.error.main : color
          }`,
          marginRight: 10,
        }}
      />
      <Typography variant="body1">{text}</Typography>
    </Stack>
  );
}
