import { createReducer } from "@reduxjs/toolkit";
import { createAsyncSlice } from "@pd/redux/types";
import pla from "../actions";
import { LinkedAcctType, PlaidLinkedAcctsStateType } from "../../types";

export const initialState: PlaidLinkedAcctsStateType = createAsyncSlice({
  accounts: [],
  linkAccountId: "",
});

export default function plaidAccountsReducer() {
  return createReducer<PlaidLinkedAcctsStateType>(initialState, (builder) => {
    builder
      .addCase(pla.accounts.apiFetching, onApiFetching)
      .addCase(pla.accounts.apiSuccess, onApiSuccess)
      .addCase(pla.accounts.apiError, onApiError)
      .addCase(pla.accounts.setLinkAccountId, onSetLinkAccountId)
      .addCase(pla.accounts.setAcctUpdateOnError, onSetUpdatedAcctOnError)
      .addCase(pla.accounts.resetAcctError, onResetAcctError)
      .addCase(pla.accounts.clearAccounts, onClearAccounts)
      .addCase(pla.accounts.setBankAccounts, onSetBankAccounts);
  });
}

export function onApiFetching(
  state: PlaidLinkedAcctsStateType,
  action: ReturnType<typeof pla.accounts.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiSuccess(
  state: PlaidLinkedAcctsStateType,
  action: ReturnType<typeof pla.accounts.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onApiError(
  state: PlaidLinkedAcctsStateType,
  action: ReturnType<typeof pla.accounts.apiError>,
) {
  state.error = action.payload.error;
}

export function onSetLinkAccountId(
  state: PlaidLinkedAcctsStateType,
  action: ReturnType<typeof pla.accounts.setLinkAccountId>,
) {
  state.data.linkAccountId = action.payload.accountId;
}

export function onSetBankAccounts(
  state: PlaidLinkedAcctsStateType,
  action: ReturnType<typeof pla.accounts.setBankAccounts>,
) {
  state.data.accounts = action.payload.accounts
    .filter((acct) => acct.subType === "checking" && acct.isDefault)
    .map((acct) => ({
      account: {
        id: acct.id,
        name: acct.name,
        type: acct.type,
        subtype: acct.subType,
        verificationStatus: acct.active ? "" : "not_active",
        mask: acct.mask,
      },
      institution: {
        name: acct.institutionName,
        id: acct.institutionName,
      },
      error: {
        displayMessage: "",
        errorCode: "",
        errorMessage: "",
        errorType: "",
      },
    }));
}

export function onSetUpdatedAcctOnError(
  state: PlaidLinkedAcctsStateType,
  action: ReturnType<typeof pla.accounts.setAcctUpdateOnError>,
) {
  const { error, metaData } = action.payload;
  if (!state.data.accounts.length) {
    const newAcct: LinkedAcctType = {
      account: {
        id: "",
        name: "",
        type: "",
        subtype: "",
        verificationStatus: "",
        mask: "",
      },
      institution: {
        name: metaData.institution?.name || "",
        id: metaData.institution?.institution_id || "",
      },
      error: {
        displayMessage: error.display_message,
        errorCode: error.error_code,
        errorMessage: error.error_message,
        errorType: error.error_type,
      },
    };
    if (newAcct.institution.id) {
      state.data.accounts = [newAcct];
    }
  } else {
    // If the institution is already in the list & there's no active error, then ignore the error
    if (
      state.data.accounts.some(
        (acct) =>
          acct.institution.id === metaData.institution?.institution_id &&
          !acct.error.displayMessage,
      )
    ) {
      return;
    }
    // If the institution is not in the list, then add it with the error
    if (
      state.data.accounts.every(
        (acct) => acct.institution.id !== metaData.institution?.institution_id,
      )
    ) {
      const newAcct: LinkedAcctType = {
        account: {
          id: "",
          name: "",
          type: "",
          subtype: "",
          verificationStatus: "",
          mask: "",
        },
        institution: {
          name: metaData.institution?.name || "",
          id: metaData.institution?.institution_id || "",
        },
        error: {
          displayMessage: error.display_message,
          errorCode: error.error_code,
          errorMessage: error.error_message,
          errorType: error.error_type,
        },
      };
      if (newAcct.institution.id) {
        state.data.accounts = state.data.accounts.concat(newAcct);
      }
      return;
    }

    // if there are previous accounts, then update the account's error data, matched by institutionId.
    const nonMatchingAccts = state.data.accounts.filter((acct) =>
      [acct.institution.id !== metaData?.institution?.institution_id].every(
        Boolean,
      ),
    );
    const errorAcct = state.data.accounts.find(
      (acct) => acct.institution.id === metaData?.institution?.institution_id,
    );
    if (!errorAcct) {
      state.data.accounts = nonMatchingAccts;
    } else {
      errorAcct.error = {
        displayMessage: error.display_message,
        errorCode: error.error_code,
        errorMessage: error.error_message,
        errorType: error.error_type,
      };
      state.data.accounts = nonMatchingAccts.concat(errorAcct);
    }
  }
}

export function onResetAcctError(
  state: PlaidLinkedAcctsStateType,
  action: ReturnType<typeof pla.accounts.resetAcctError>,
) {
  const { accountId } = action.payload;
  const updatedLinkedAccts = state.data.accounts.map((_acct) => {
    const acct = { ..._acct };
    if (acct.account.id === accountId) {
      acct.account.verificationStatus = "";
      acct.error = {
        displayMessage: "",
        errorCode: "",
        errorMessage: "",
        errorType: "",
      };
    }
    return acct;
  });
  state.data.accounts = updatedLinkedAccts;
}

export function onClearAccounts(state: PlaidLinkedAcctsStateType) {
  state.data.accounts = [];
}
