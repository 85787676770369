import { createAction } from "@reduxjs/toolkit";
import { TeamMemberDbType } from "@pd/redux/types/dbTypes";
import { ErrorPayload } from "@pd/api/utils/safeFetch";

const allTeamMembersActions = {
  apiFetching: createAction(
    "dashboard/teamMembers/all/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction(
    "dashboard/teamMembers/all/apiError",
    (error: ErrorPayload) => ({
      payload: { error },
    }),
  ),
  apiSuccess: createAction(
    "dashboard/teamMembers/all/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  getAllTeamMembers: createAction(
    "dashboard/teamMembers/all/getAllTeamMembers",
  ),
  setAllTeamMembers: createAction(
    "dashboard/teamMembers/all/setAllTeamMembers",
    (team: TeamMemberDbType[]) => ({
      payload: { team },
    }),
  ),
};

export default allTeamMembersActions;
