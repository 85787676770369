// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function ButtonBase() {
  return {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          "&:focus": {
            outline: "none",
            borderRadius: "5px",
          },
        },
      },
    },
  };
}
