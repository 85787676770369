import { Button, Typography, Stack } from "@mui/material";
import { WarningOutlined } from "@ant-design/icons";

type Props = {
  onClose: () => void;
  onLinkAccount: (args: { retry: boolean; accountId: string }) => void;
};

export default function RetryErrorMsg(props: Props) {
  return (
    <Stack>
      <Typography variant="body1">Link Error</Typography>
      <WarningOutlined />
      <Typography variant="body1">
        We were unable to link your account. Please try again.
      </Typography>
      <Button
        variant="contained"
        onClick={() => props.onLinkAccount({ retry: true, accountId: "" })}
      >
        Retry
      </Button>
      <Button variant="outlined" onClick={() => props.onClose()}>
        Cancel
      </Button>
    </Stack>
  );
}
