import { all, put, call, takeLeading } from "redux-saga/effects";
import { fetchBankAccounts, BankAccountsResType } from "@pd/api/banking";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";
import pla from "../actions";

export default function* accountsSaga() {
  yield all([takeLeading(pla.accounts.getBankAccounts, onGetBankAccounts)]);
}

function* onGetBankAccounts() {
  try {
    yield all([
      put(pla.accounts.apiFetching(true)),
      put(pla.accounts.apiSuccess(false)),
      put(pla.accounts.apiError({ message: "", status: 0 })),
    ]);
    const res: BankAccountsResType = yield call(fetchBankAccounts);
    if ("error" in res) {
      yield all([
        put(pla.accounts.apiSuccess(false)),
        put(pla.accounts.apiFetching(false)),
        put(pla.accounts.apiError(res.error)),
      ]);
    } else {
      yield all([
        put(pla.accounts.apiFetching(false)),
        put(pla.accounts.apiSuccess(true)),
        put(pla.accounts.setBankAccounts(res.data)),
      ]);
    }
  } catch (error: unknown) {
    const errMsg =
      "An error occured while attempting to connect to Plaid. Please try again later.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        pla.accounts.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(pla.accounts.apiSuccess(false)),
      put(pla.accounts.apiFetching(false)),
    ]);
  }
}

export function* rehydrateBankAccounts() {
  yield put(pla.accounts.apiFetching(true));
  yield put(pla.accounts.clearAccounts());
  yield put(pla.accounts.getBankAccounts());
}
