import { LinkedAcctType } from "@pd/components/PlaidLink/types";
import { AsyncStateType } from "../../../../redux/types";

// NOTE: FlowTypes are the keys of the modalTransitionMap object in frontend/src/layouts/BuyerPortal/redux/sagas/constants.ts
export enum FlowTypes {
  selectPayMethod = "selectPayMethod",
  paymentReview = "paymentReview",
  paymentConfirmed = "paymentConfirmed",
  paymentCalendar = "paymentCalendar",
  autopayReview = "autopayReview",
  paymentRetryError = "paymentRetryError",
  paymentRelinkError = "paymentRelinkError",
  autopayConfirmed = "autopayConfirmed",
  editPayMethod = "editPayMethod",
  plaidLinking = "plaidLinking",
  plaidError = "plaidError",
}

// NOTE: FlowSubTypes are the keys of the objects in frontend/src/layouts/BuyerPortal/redux/sagas/constants.ts
export enum FlowSubTypes {
  pickDate = "pickDate",
  goTo = "goTo",
  next = "next",
  back = "back",
  retry = "retry",
  props = "props",
  relink = "relink",
  error = "error",
  payByBank = "payByBank",
  payByAchCredit = "payByAchCredit",
  payByAchDebit = "payByAchDebit",
  payByWire = "payByWire",
  payByCard = "payByCard",
  payByCheck = "payByCheck",
}

export type GoToSlugType = {
  slug: string;
};

export type ModalStringErrorPropsType = {
  [FlowSubTypes.error]: string | null;
};

interface BaseModalType {
  [FlowSubTypes.next]: FlowTypes;
  [FlowSubTypes.back]: FlowTypes;
}

export type SelectPaymentMethodType = {
  [FlowSubTypes.payByBank]: FlowTypes;
  [FlowSubTypes.payByAchCredit]: FlowSubTypes;
  [FlowSubTypes.payByWire]: FlowSubTypes;
  [FlowSubTypes.payByCard]: FlowSubTypes;
  [FlowSubTypes.payByCheck]: FlowSubTypes;
};

export type PlaidLinking = {
  [FlowSubTypes.error]: FlowTypes;
  [FlowSubTypes.next]: FlowTypes;
};

export type PlaidErrorType = {
  [FlowSubTypes.props]: ModalStringErrorPropsType;
  [FlowSubTypes.retry]: FlowTypes;
};

export type PaymentReviewType = BaseModalType & {
  [FlowSubTypes.pickDate]: FlowTypes;
  [FlowSubTypes.goTo]: GoToSlugType;
  [FlowTypes.editPayMethod]: FlowTypes;
  [FlowTypes.paymentRelinkError]: FlowTypes;
  [FlowTypes.paymentRetryError]: FlowTypes;
};
export type PaymentReviewPropType = {
  [FlowSubTypes.back]: FlowTypes;
};

export type PaymentConfirmedType = {
  [FlowTypes.autopayReview]: FlowTypes;
  [FlowSubTypes.goTo]: GoToSlugType;
};

export type PaymentCalendarType = {
  [FlowSubTypes.goTo]: GoToSlugType;
  [FlowTypes.editPayMethod]: FlowTypes;
  [FlowSubTypes.next]: FlowTypes;
  [FlowSubTypes.error]: FlowTypes;
  [FlowSubTypes.relink]: FlowTypes;
  [FlowSubTypes.back]: FlowTypes;
};

export type AutoPayReviewType = {
  [FlowTypes.editPayMethod]: FlowTypes;
  [FlowSubTypes.pickDate]: FlowTypes;
  [FlowSubTypes.next]: FlowTypes;
};
export type AutoPayReviewPropType = {
  [FlowSubTypes.back]: FlowTypes;
};

export type PaymentRetryErrorType = {
  [FlowSubTypes.props]: ModalStringErrorPropsType;
  [FlowSubTypes.retry]: FlowTypes;
};

export type PaymentRelinkErrorType = {
  [FlowSubTypes.props]: ModalStringErrorPropsType;
  [FlowSubTypes.retry]: FlowTypes;
};

export type AutopayConfirmedType = {
  [FlowSubTypes.goTo]: GoToSlugType;
};

export type EditPayMethodPropType = {
  linkedAccount: string | null;
};

export type EditPayMethodType = {
  [FlowSubTypes.props]: EditPayMethodPropType;
  [FlowSubTypes.next]: FlowTypes;
  [FlowSubTypes.back]: FlowTypes;
};

export type PayByAchCreditType = {
  [FlowSubTypes.next]: FlowTypes;
  [FlowSubTypes.back]: FlowTypes;
};

export type PayByWireType = {
  [FlowSubTypes.next]: FlowTypes;
  [FlowSubTypes.back]: FlowTypes;
};

export type PayByCardType = {
  [FlowSubTypes.next]: FlowTypes;
  [FlowSubTypes.back]: FlowTypes;
};

export type PayByCheckType = {
  [FlowSubTypes.next]: FlowTypes;
  [FlowSubTypes.back]: FlowTypes;
};

export type ModalTypes = {
  [FlowTypes.selectPayMethod]: SelectPaymentMethodType;
  [FlowTypes.plaidLinking]: PlaidLinking;
  [FlowTypes.plaidError]: PlaidErrorType;
  [FlowTypes.paymentReview]: PaymentReviewType;
  [FlowTypes.paymentConfirmed]: PaymentConfirmedType;
  [FlowTypes.paymentCalendar]: PaymentCalendarType;
  [FlowTypes.autopayReview]: AutoPayReviewType;
  [FlowTypes.paymentRetryError]: PaymentRetryErrorType;
  [FlowTypes.paymentRelinkError]: PaymentRelinkErrorType;
  [FlowTypes.autopayConfirmed]: AutopayConfirmedType;
  [FlowTypes.editPayMethod]: EditPayMethodType;
};

export type ModalTransitionSubMapType = {
  [key in FlowSubTypes]?:
    | FlowTypes
    | GoToSlugType
    | EditPayMethodPropType
    | ModalStringErrorPropsType
    | AutoPayReviewPropType
    | PaymentReviewPropType
    | PayByAchCreditType
    | PayByWireType
    | PayByCardType
    | PayByCheckType;
};

export type FlowTypeUnionType = keyof typeof FlowTypes;

export type ModalTransitionMapType = {
  [key in FlowTypes]: ModalTypes[key];
};

export type NextFlowTypes =
  | FlowTypes
  | FlowSubTypes
  | GoToSlugType
  | undefined
  | ModalStringErrorPropsType
  | EditPayMethodPropType
  | AutoPayReviewPropType
  | PaymentReviewPropType;

export type NextFlowPropType =
  | ModalStringErrorPropsType
  | EditPayMethodPropType
  | AutoPayReviewPropType
  | PaymentReviewPropType
  | null;

export type ModalStateType = AsyncStateType & {
  activeModal: NextFlowTypes | undefined;
  props: NextFlowPropType;
  paymentMethod: LinkedAcctType | null;
  paymentDate: string | null;
};

export function isSelectPaymentMethodType(
  path: ModalTransitionMapType[FlowTypes],
): path is SelectPaymentMethodType {
  return (
    "payByBank" in path ||
    "payByCard" in path ||
    "payByCheck" in path ||
    "payByWire" in path ||
    "payByAchCredit" in path
  );
}
export function isSelectPaymentMethodSubType(
  actionTaken: FlowTypes | FlowSubTypes,
): actionTaken is keyof SelectPaymentMethodType {
  return (
    actionTaken === FlowSubTypes.payByBank ||
    actionTaken === FlowSubTypes.payByCard ||
    actionTaken === FlowSubTypes.payByCheck ||
    actionTaken === FlowSubTypes.payByWire ||
    actionTaken === FlowSubTypes.payByAchCredit
  );
}

export function isPlaidLinkingType(
  path: ModalTransitionMapType[FlowTypes],
): path is PlaidLinking {
  return "error" in path && "next" in path;
}
export function isPlaidLinkingSubType(
  actionTaken: FlowTypes | FlowSubTypes,
): actionTaken is keyof PlaidLinking {
  return (
    actionTaken === FlowSubTypes.error || actionTaken === FlowSubTypes.next
  );
}

export function isPlaidErrorType(
  path: ModalTransitionMapType[FlowTypes],
): path is PlaidErrorType {
  return "props" in path && "retry" in path;
}
export function isPlaidErrorSubType(
  actionTaken: FlowTypes | FlowSubTypes,
): actionTaken is keyof PlaidErrorType {
  return (
    actionTaken === FlowSubTypes.props || actionTaken === FlowSubTypes.retry
  );
}

export function isPaymentReviewType(
  path: ModalTransitionMapType[FlowTypes],
): path is PaymentReviewType {
  return (
    "pickDate" in path &&
    "goTo" in path &&
    "editPayMethod" in path &&
    "paymentRelinkError" in path &&
    "paymentRetryError" in path
  );
}
export function isPaymentReviewSubType(
  actionTaken: FlowTypes | FlowSubTypes,
): actionTaken is keyof PaymentReviewType {
  return (
    actionTaken === FlowSubTypes.pickDate ||
    actionTaken === FlowSubTypes.goTo ||
    actionTaken === FlowTypes.editPayMethod ||
    actionTaken === FlowTypes.paymentRelinkError ||
    actionTaken === FlowTypes.paymentRetryError ||
    actionTaken === FlowSubTypes.next ||
    actionTaken === FlowSubTypes.back
  );
}

export function isPaymentConfirmedType(
  path: ModalTransitionMapType[FlowTypes],
): path is PaymentConfirmedType {
  return "autopayReview" in path && "goTo" in path;
}
export function isPaymentConfirmedSubType(
  actionTaken: FlowTypes | FlowSubTypes,
): actionTaken is keyof PaymentConfirmedType {
  return (
    actionTaken === FlowTypes.autopayReview || actionTaken === FlowSubTypes.goTo
  );
}

export function isPaymentCalendarType(
  path: ModalTransitionMapType[FlowTypes],
): path is PaymentCalendarType {
  return (
    "goTo" in path &&
    "editPayMethod" in path &&
    "next" in path &&
    "error" in path &&
    "relink" in path &&
    "back" in path
  );
}
export function isPaymentCalendarSubType(
  actionTaken: FlowTypes | FlowSubTypes,
): actionTaken is keyof PaymentCalendarType {
  return (
    actionTaken === FlowSubTypes.goTo ||
    actionTaken === FlowTypes.editPayMethod ||
    actionTaken === FlowSubTypes.next ||
    actionTaken === FlowSubTypes.error ||
    actionTaken === FlowSubTypes.relink ||
    actionTaken === FlowSubTypes.back
  );
}

export function isAutoPayReviewType(
  path: ModalTransitionMapType[FlowTypes],
): path is AutoPayReviewType {
  return "editPayMethod" in path && "next" in path;
}
export function isAutoPayReviewSubType(
  actionTaken: FlowTypes | FlowSubTypes,
): actionTaken is keyof AutoPayReviewType {
  return (
    actionTaken === FlowTypes.editPayMethod || actionTaken === FlowSubTypes.next
  );
}

export function isPaymentRetryErrorType(
  path: ModalTransitionMapType[FlowTypes],
): path is PaymentRetryErrorType {
  return "props" in path && "retry" in path;
}
export function isPaymentRetryErrorSubType(
  actionTaken: FlowTypes | FlowSubTypes,
): actionTaken is keyof PaymentRetryErrorType {
  return (
    actionTaken === FlowSubTypes.props || actionTaken === FlowSubTypes.retry
  );
}

export function isPaymentRelinkErrorType(
  path: ModalTransitionMapType[FlowTypes],
): path is PaymentRelinkErrorType {
  return "props" in path && "retry" in path;
}
export function isPaymentRelinkErrorSubType(
  actionTaken: FlowTypes | FlowSubTypes,
): actionTaken is keyof PaymentRelinkErrorType {
  return (
    actionTaken === FlowSubTypes.props || actionTaken === FlowSubTypes.retry
  );
}

export function isAutopayConfirmedType(
  path: ModalTransitionMapType[FlowTypes],
): path is AutopayConfirmedType {
  return "goTo" in path;
}
export function isAutopayConfirmedSubType(
  actionTaken: FlowTypes | FlowSubTypes,
): actionTaken is keyof AutopayConfirmedType {
  return actionTaken === FlowSubTypes.goTo;
}

export function isEditPayMethodType(
  path: ModalTransitionMapType[FlowTypes],
): path is EditPayMethodType {
  return "props" in path && "next" in path && "back" in path;
}
export function isEditPayMethodSubType(
  actionTaken: FlowTypes | FlowSubTypes,
): actionTaken is keyof EditPayMethodType {
  return (
    actionTaken === FlowSubTypes.props ||
    actionTaken === FlowSubTypes.next ||
    actionTaken === FlowSubTypes.back
  );
}

export function isGoToSlugType(path: any): path is GoToSlugType {
  return typeof path === "object" && "slug" in path;
}

export function isAutopayReviewPropType(
  props: NextFlowPropType | undefined,
): props is AutoPayReviewPropType {
  if (!props) {
    return false;
  }
  return (
    FlowSubTypes.back in props &&
    props[FlowSubTypes.back] === FlowTypes.autopayReview
  );
}
export function isPaymentReviewPropType(
  props: NextFlowPropType | undefined,
): props is PaymentReviewPropType {
  if (!props) {
    return false;
  }
  return (
    FlowSubTypes.back in props &&
    props[FlowSubTypes.back] === FlowTypes.paymentReview
  );
}
