import { Outlet } from "react-router";
import { Stack, Box } from "@mui/material";
import StitchLogo from "@pd/assets/svgs/stitch-logo_full_v2.svg";
import RightSideIcons from "@pd/assets/images/auth/right-side-icons.svg";

export default function AuthLogin() {
  return (
    <Stack
      alignItems="flex-start"
      direction="row"
      sx={{
        height: "100vh",
        width: "100vw",
        justifyContent: "center",
        pl: "20px",
      }}
    >
      <Stack
        data-testid="left-login"
        sx={{ width: "50%", height: "100%", p: "50px" }}
      >
        <Box>
          <img src={StitchLogo} alt="Stitch Logo" />
        </Box>
        <Outlet />
      </Stack>
      <Stack
        data-testid="right-login"
        sx={{
          padding: "50px",
          borderLeft: "1px solid black",
          width: "50%",
          height: "100%",
          backgroundColor: "#F2F8FC",
        }}
        justifyContent="center"
        alignItems="center"
      >
        <img
          alt="Right Side Icons"
          src={RightSideIcons}
          style={{ width: "100%", maxWidth: "500px" }}
        />
      </Stack>
    </Stack>
  );
}
