import { createAction } from "@reduxjs/toolkit";
import { BuyerDbType, BuyersSearchDbType } from "@pd/redux/types/dbTypes";
import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { BuyerReqType } from "@pd/layouts/MktplaceDashboard/types/allBuyers";

const allBuyerActions = {
  apiFetching: createAction(
    "dashboard/buyers/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction(
    "dashboard/buyers/apiError",
    (error: ErrorPayload) => ({
      payload: { error },
    }),
  ),
  apiSuccess: createAction(
    "dashboard/buyers/all/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  getBuyers: createAction("dashboard/buyers/all/getBuyers"),
  setBuyers: createAction(
    "dashboard/buyers/all/setBuyers",
    (buyers: BuyerDbType[]) => ({
      payload: { buyers },
    }),
  ),
  getBuyersSearch: createAction(
    "dashboard/buyers/all/getBuyersSearch",
    (query: string) => ({
      payload: { query },
    }),
  ),
  setBuyersSearch: createAction(
    "dashboard/buyers/all/setBuyersSearch",
    (results: BuyerDbType[]) => ({
      payload: { results },
    }),
  ),
  createBuyer: createAction(
    "dashboard/buyers/all/createBuyer",
    (buyer: BuyerReqType) => ({
      payload: { buyer },
    }),
  ),
  setCreatedBuyer: createAction(
    "dashboard/buyers/all/setCreatedBuyer",
    (buyer: BuyerDbType) => ({
      payload: { buyer },
    }),
  ),
  setFilteredBuyers: createAction(
    "dashboard/buyers/all/setFilteredBuyers",
    (buyers: BuyerDbType[]) => ({
      payload: { buyers },
    }),
  ),
  searchBuyers: createAction(
    "dashboard/buyers/all/searchBuyers",
    (query: string) => ({
      payload: { query },
    }),
  ),
  setSearchedBuyers: createAction(
    "dashboard/buyers/all/setSearchedBuyers",
    (searchedBuyers: BuyersSearchDbType[]) => ({
      payload: { searchedBuyers },
    }),
  ),
  clearSearchedBuyers: createAction("dashboard/buyers/all/clearSearchedBuyers"),
};

export default allBuyerActions;
