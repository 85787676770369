import { createSelector } from "@reduxjs/toolkit";
import { AppStateType } from "@pd/redux/store/reducers";
import { OrderDbType } from "@pd/redux/types/dbTypes";

import { isDashboardState } from "../../../types";

export const selectAllOrders = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): OrderDbType[] => {
    if (isDashboardState(state)) {
      const data = state.dashboard.orders.allOrders.data.orders;
      if (data) {
        return data;
      }
    }
    return [];
  },
);

export const selectAllOrdersFetching = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType) => {
    if (isDashboardState(state)) {
      return state.dashboard.orders.allOrders.fetching;
    }
    return false;
  },
);

export const selectAllOrdersSuccess = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType) => {
    if (isDashboardState(state)) {
      return state.dashboard.orders.allOrders.success;
    }
    return false;
  },
);

export const selectAllOrdersError = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType) => {
    if (isDashboardState(state)) {
      return state.dashboard.orders.allOrders.error;
    }
    return { message: "", status: 0 };
  },
);

export const selectAllOrdersFiltered = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType) => {
    if (isDashboardState(state)) {
      return state.dashboard.orders.allOrders.data.filteredOrders || [];
    }
    return [];
  },
);

export const selectSelectedOrders = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType) => {
    if (isDashboardState(state)) {
      return state.dashboard.orders.allOrders.data.selectedOrders;
    }
    return [];
  },
);

export const selectBulkPayoutInfo = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType) => {
    if (isDashboardState(state)) {
      const payoutInfo = state.dashboard.orders.allOrders.data.bulkPayout?.info;
      if (payoutInfo) {
        return payoutInfo;
      }
    }
    return {
      vendorsCount: 0,
      ordersCount: 0,
      distributedTotal: 0,
    };
  },
);

export const selectBulkPayoutResults = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType) => {
    if (isDashboardState(state)) {
      const payoutResults =
        state.dashboard.orders.allOrders.data.bulkPayout?.results;
      if (payoutResults) {
        return payoutResults;
      }
    }
    return { actionSize: 0, successCount: 0, errors: [] };
  },
);
