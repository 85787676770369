import { createAction } from "@reduxjs/toolkit";
import {
  OrderDetailDbType,
  OrderCreatedDbType,
  OrderLineItemDbType,
} from "@pd/redux/types/dbTypes";
import { CreateOrderFormTypes } from "@pd/layouts/MktplaceDashboard/pages/Orders/components/CreateEditOrder/types";
import { ErrorPayload } from "@pd/api/utils/safeFetch";

const crudOrderActions = {
  apiFetching: createAction(
    "dashboard/crudOrder/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction(
    "dashboard/crudOrder/apiError",
    (error: ErrorPayload) => ({
      payload: { error },
    }),
  ),
  apiSuccess: createAction(
    "dashboard/crudOrder/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  createOrder: createAction(
    "dashboard/crudOrder/createOrder",
    (order: CreateOrderFormTypes, confirm: boolean) => ({
      payload: { order, confirm },
    }),
  ),
  getApiOrder: createAction(
    "dashboard/crudOrder/getApiOrder",
    (id: string) => ({
      payload: { id },
    }),
  ),
  editOrder: createAction(
    "dashboard/crudOrder/editOrder",
    (order: CreateOrderFormTypes, id: string, confirm: boolean) => ({
      payload: { order, id, confirm },
    }),
  ),
  setOrderApiData: createAction(
    "dashboard/crudOrder/setOrderApiData",
    (order: OrderCreatedDbType | null) => ({
      payload: { order },
    }),
  ),
  setOrderUiData: createAction(
    "dashboard/crudOrder/setOrderUiData",
    (
      key: string,
      value:
        | string
        | number
        | OrderLineItemDbType[]
        | { amount: number }
        | boolean,
    ) => ({
      payload: { key, value },
    }),
  ),
  clearUpsertOrder: createAction("dashboard/crudOrder/clearUpsertOrder"),
  clearOrderById: createAction("dashboard/crudOrder/clearOrderById"),
  updateOrderTotals: createAction("dashboard/crudOrder/updateOrderTotals"),
  getPreviewOrderTotals: createAction(
    "dashboard/crudOrder/getPreviewOrderTotals",
    (order: CreateOrderFormTypes, orderId?: string) => ({
      payload: { order, orderId },
    }),
  ),
  deleteOrder: createAction(
    "dashboard/crudOrder/deleteOrder",
    (id: string) => ({
      payload: { id },
    }),
  ),
  voidOrder: createAction("dashboard/crudOrder/voidOrder", (id: string) => ({
    payload: { id },
  })),
  setDeletedOrder: createAction(
    "dashboard/crudOrder/setDeletedOrder",
    (id: string) => ({
      payload: { id },
    }),
  ),
  getOrderById: createAction(
    "dashboard/crudOrder/getOrderById",
    (id: string) => ({
      payload: { id },
    }),
  ),
  setOrderById: createAction(
    "dashboard/crudOrder/setOrderById",
    (order: OrderDetailDbType) => ({
      payload: { order },
    }),
  ),
};

export default crudOrderActions;
