import { useState } from "react";
import {
  Modal,
  ButtonBase,
  Fade,
  Stack,
  Backdrop,
  Typography,
} from "@mui/material";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import { FlowTypes, FlowSubTypes } from "../../redux/types/modalTypes";
import { modalStyles } from "../constants/styles";

type Props = {
  onClose: () => void;
  onOpen: (caller: FlowTypes, actionTaken: FlowTypes | FlowSubTypes) => void;
};
export default function SelectPayMethod(props: Props) {
  const [open, setOpen] = useState(true);
  const handleOpenBank = () => {
    props.onOpen(FlowTypes.selectPayMethod, FlowSubTypes.payByBank);
  };
  const handleClose = () => {
    props.onClose();
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}
    >
      <Fade in>
        <Stack gap={2} sx={modalStyles}>
          <Stack justifyContent="space-between" direction="row">
            <ButtonBase onClick={handleClose}>
              <ArrowLeftOutlined />
            </ButtonBase>
            <Typography variant="body1">Select Pay Method</Typography>
            <ButtonBase onClick={handleClose}>
              <CloseOutlined />
            </ButtonBase>
          </Stack>
          <ButtonBase disableRipple onClick={handleOpenBank}>
            Pay By Bank
          </ButtonBase>
          <ButtonBase disableRipple onClick={() => alert("pay by ach credit")}>
            Pay By ACH Credit
          </ButtonBase>
          <ButtonBase disableRipple onClick={() => alert("pay by Wire")}>
            Pay By Wire
          </ButtonBase>
          <ButtonBase disableRipple onClick={() => alert("pay by Card")}>
            Pay By Card
          </ButtonBase>
          <ButtonBase disableRipple onClick={() => alert("pay by Check")}>
            Pay By Check
          </ButtonBase>
        </Stack>
      </Fade>
    </Modal>
  );
}
