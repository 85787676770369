import { Stack, Typography } from "@mui/material";

export default function ColdLoginSubMsg() {
  return (
    <Stack
      sx={{ width: "100%", mt: 5 }}
      gap={1}
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="body2">What is Stitch?</Typography>
      <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
        Stitch is a financing and payments platform for fast, easy and flexible
        invoice management. If {"you're"} working with a retailer that uses
        Stitch, enter the email you registered to login in.
        <br />
        <br />
        Learn more at www.withstitch.com
      </Typography>
    </Stack>
  );
}
