import { createAsyncSlice } from "@pd/redux/types";
import { createReducer } from "@reduxjs/toolkit";
import { AllOrderStateType } from "@pd/layouts/MktplaceDashboard/types/allOrders";
import da from "../../actions";

export const initAllOrdersState = createAsyncSlice({
  orders: [],
  filteredOrders: [],
  selectedOrders: [],
  bulkPayout: {
    info: null,
    results: null,
  },
});

export default function createAllOrdersReducer() {
  return createReducer<AllOrderStateType>(initAllOrdersState, (builder) => {
    builder
      .addCase(da.orders.all.apiFetching, onApiFetching)
      .addCase(da.orders.all.apiError, onApiError)
      .addCase(da.orders.all.apiSuccess, onApiSuccess)
      .addCase(da.orders.all.setAllOrders, onSetAllOrders)
      .addCase(da.orders.crud.setDeletedOrder, onSetDeletedOrder)
      .addCase(da.orders.all.setFilteredOrders, onSetFilteredOrders)
      .addCase(da.orders.all.setSelectedOrders, onSetSelectedOrders)
      .addCase(da.orders.all.setSelectedOrder, onSetSelectedOrder)
      .addCase(da.orders.all.setBulkPayoutInfo, onSetBulkPayoutInfo)
      .addCase(da.orders.all.setBulkPayoutResults, onSetBulkPayoutResults)
      .addCase(da.orders.all.clearBulkPayoutInfo, onClearBulkPayoutInfo);
  });
}

export function onApiFetching(
  state: AllOrderStateType,
  action: ReturnType<typeof da.orders.all.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: AllOrderStateType,
  action: ReturnType<typeof da.orders.all.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: AllOrderStateType,
  action: ReturnType<typeof da.orders.all.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onSetAllOrders(
  state: AllOrderStateType,
  action: ReturnType<typeof da.orders.all.setAllOrders>,
) {
  state.data.orders = action.payload.orders;
}

export function onSetDeletedOrder(
  state: AllOrderStateType,
  action: ReturnType<typeof da.orders.crud.setDeletedOrder>,
) {
  state.data.orders = state.data.orders.filter(
    (order) => order.id !== action.payload.id,
  );
}

export function onSetFilteredOrders(
  state: AllOrderStateType,
  action: ReturnType<typeof da.orders.all.setFilteredOrders>,
) {
  state.data.filteredOrders = action.payload.orders;
}

export function onSetSelectedOrders(
  state: AllOrderStateType,
  action: ReturnType<typeof da.orders.all.setSelectedOrders>,
) {
  state.data.selectedOrders = action.payload.ids;
}

export function onSetSelectedOrder(
  state: AllOrderStateType,
  action: ReturnType<typeof da.orders.all.setSelectedOrder>,
) {
  if (state.data.selectedOrders.length) {
    state.data.selectedOrders = state.data.selectedOrders.includes(
      action.payload.id,
    )
      ? state.data.selectedOrders.filter((id) => id !== action.payload.id)
      : [...state.data.selectedOrders, action.payload.id];
  } else {
    state.data.selectedOrders = [action.payload.id];
  }
}

export function onSetBulkPayoutInfo(
  state: AllOrderStateType,
  action: ReturnType<typeof da.orders.all.setBulkPayoutInfo>,
) {
  state.data.bulkPayout.info = action.payload.info;
}

export function onSetBulkPayoutResults(
  state: AllOrderStateType,
  action: ReturnType<typeof da.orders.all.setBulkPayoutResults>,
) {
  state.data.bulkPayout.results = action.payload.results;
}

export function onClearBulkPayoutInfo(state: AllOrderStateType) {
  state.data.bulkPayout = {
    info: null,
    results: null,
  };
}
