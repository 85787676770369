import { Button, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import modalActions from "@pd/layouts/BuyerPortal/redux/actions/modalActions";
import { FlowTypes } from "@pd/layouts/BuyerPortal/redux/types/modalTypes";
import { PlaidLinkStateType } from "@pd/components/PlaidLink/types";

export default function BuyerHome() {
  const dispatch = useAppDispatch();
  const linkState: PlaidLinkStateType = useAppSelector(
    (state) => state.plaidLink,
  );

  const targetAccount = linkState.linkedAccounts.data.reverse()[0];

  function handleSchedulePayment() {
    if (!targetAccount) {
      dispatch(modalActions.open(FlowTypes.selectPayMethod));
    } else {
      dispatch(modalActions.open(FlowTypes.paymentReview));
    }
  }

  return (
    <Stack
      alignItems="flex-start"
      sx={{
        mt: "100px",
        pl: "40px",
      }}
    >
      <h1>Buyer Home</h1>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleSchedulePayment()}
      >
        Schedule Payment
      </Button>
    </Stack>
  );
}
