import { createAction } from "@reduxjs/toolkit";
import {
  BulkPayoutInfoDbType,
  BulkPayoutResultDbType,
  OrderDbType,
} from "@pd/redux/types/dbTypes";
import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { OrderStatus } from "@pd/redux/types/orders";

const allOrdersActions = {
  apiFetching: createAction(
    "dashboard/orders/all/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction(
    "dashboard/orders/all/apiError",
    (error: ErrorPayload) => ({
      payload: { error },
    }),
  ),
  apiSuccess: createAction(
    "dashboard/orders/all/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  getAllOrders: createAction("dashboard/orders/all/getTableOrders"),
  setAllOrders: createAction(
    "dashboard/orders/all/setAllOrders",
    (orders: OrderDbType[]) => ({
      payload: { orders },
    }),
  ),
  setFilteredOrders: createAction(
    "dashboard/orders/all/setFilteredOrders",
    (orders: OrderDbType[]) => ({
      payload: { orders },
    }),
  ),
  getSelectedOrders: createAction(
    "dashboard/orders/all/getSelectedOrders",
    (status: OrderStatus | undefined) => ({
      payload: { status },
    }),
  ),
  setSelectedOrders: createAction(
    "dashboard/orders/all/setSelectedOrders",
    (ids: string[]) => ({
      payload: { ids },
    }),
  ),
  setSelectedOrder: createAction(
    "dashboard/orders/all/setSelectedOrder",
    (orderId: string) => ({
      payload: { id: orderId },
    }),
  ),
  deleteSelectedOrders: createAction(
    "dashboard/orders/all/deleteSelectedOrders",
  ),
  confirmSelectedOrders: createAction(
    "dashboard/orders/all/confirmSelectedOrders",
  ),
  voidSelectedOrders: createAction("dashboard/orders/all/voidSelectedOrders"),
  getBulkPayoutsInfo: createAction("dashboard/orders/all/getBulkPayoutsInfo"),
  triggerBulkPayout: createAction("dashboard/orders/all/triggerBulkPayout"),
  setBulkPayoutInfo: createAction(
    "dashboard/orders/all/setBulkPayoutInfo",
    (info: BulkPayoutInfoDbType) => ({
      payload: { info },
    }),
  ),
  setBulkPayoutResults: createAction(
    "dashboard/orders/all/setBulkPayoutResults",
    (results: BulkPayoutResultDbType) => ({
      payload: { results },
    }),
  ),
  clearBulkPayoutInfo: createAction("dashboard/orders/all/clearBulkPayoutInfo"),
};

export default allOrdersActions;
