import type { AllBuyerStateType } from "@pd/layouts/MktplaceDashboard/types/allBuyers";
import { createAsyncSlice } from "@pd/redux/types";
import { createReducer } from "@reduxjs/toolkit";
import da from "../../actions";

const initBuyerState = createAsyncSlice({
  buyers: [],
  filteredBuyers: [],
  searchedBuyers: [],
});

export default function createAllBuyersReducer() {
  return createReducer<AllBuyerStateType>(initBuyerState, (builder) => {
    builder
      .addCase(da.buyers.all.apiFetching, onApiFetching)
      .addCase(da.buyers.all.apiError, onApiError)
      .addCase(da.buyers.all.apiSuccess, onApiSuccess)
      .addCase(da.buyers.all.setCreatedBuyer, onSetCreatedBuyer)
      .addCase(da.buyers.all.setBuyers, onSetBuyers)
      .addCase(da.buyers.all.setFilteredBuyers, onSetFilteredBuyers)
      .addCase(da.buyers.all.setSearchedBuyers, onSetSearchedBuyers)
      .addCase(da.buyers.all.clearSearchedBuyers, onClearSearchedBuyers);
  });
}

export function onApiFetching(
  state: AllBuyerStateType,
  action: ReturnType<typeof da.buyers.all.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: AllBuyerStateType,
  action: ReturnType<typeof da.buyers.all.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: AllBuyerStateType,
  action: ReturnType<typeof da.buyers.all.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onSetCreatedBuyer(
  state: AllBuyerStateType,
  action: ReturnType<typeof da.buyers.all.setCreatedBuyer>,
) {
  state.data.buyers = [action.payload.buyer, ...state.data.buyers];
}

export function onSetBuyers(
  state: AllBuyerStateType,
  action: ReturnType<typeof da.buyers.all.setBuyers>,
) {
  state.data.buyers = action.payload.buyers;
}

export function onSetFilteredBuyers(
  state: AllBuyerStateType,
  action: ReturnType<typeof da.buyers.all.setFilteredBuyers>,
) {
  state.data.filteredBuyers = action.payload.buyers;
}

export function onSetSearchedBuyers(
  state: AllBuyerStateType,
  action: ReturnType<typeof da.buyers.all.setSearchedBuyers>,
) {
  state.data.searchedBuyers = action.payload.searchedBuyers;
}

export function onClearSearchedBuyers(state: AllBuyerStateType) {
  state.data.searchedBuyers = [];
}
