// material-ui
import { Box } from "@mui/material";

// project import
import stitchLogo from "@pd/assets/svgs/stitch-logo_full_v2.svg";

function WhiteLabelLogo() {
  return (
    <Box
      sx={{
        w: "75px",
      }}
    >
      <img alt="Stitch Logo" src={stitchLogo} />
    </Box>
  );
}

export default WhiteLabelLogo;
