import { createReducer } from "@reduxjs/toolkit";

import type { CrudOrderStateType } from "@pd/layouts/MktplaceDashboard/types/crudOrder";
import { createAsyncSlice } from "@pd/redux/types";
import { OrderStatus } from "@pd/redux/types/orders";
import da from "../../actions";

export const initCrudOrderState = createAsyncSlice({
  api: null,
  ui: null,
  orderById: null,
});

const uiOrderTemplate = {
  status: OrderStatus.unknown,
  buyerId: "",
  buyerName: "",
  vendorId: "",
  vendorName: "",
  referenceId: "",
  billDate: "",
  serviceDate: "",
  lineItems: [],
  deduction: {
    amount: 0,
  },
  isFinanced: false,
};

export default function createCrudOrderReducer() {
  return createReducer<CrudOrderStateType>(initCrudOrderState, (builder) => {
    builder
      .addCase(da.orders.crud.apiFetching, onApiFetching)
      .addCase(da.orders.crud.apiError, onApiError)
      .addCase(da.orders.crud.apiSuccess, onApiSuccess)
      .addCase(da.orders.crud.setOrderApiData, onSetOrderApiData)
      .addCase(da.orders.crud.setOrderUiData, onSetOrderUiData)
      .addCase(da.orders.crud.setOrderById, onSetOrderById)
      .addCase(da.orders.crud.clearUpsertOrder, onClearUpsertOrder);
  });
}

export function onApiFetching(
  state: CrudOrderStateType,
  action: ReturnType<typeof da.orders.crud.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: CrudOrderStateType,
  action: ReturnType<typeof da.orders.crud.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: CrudOrderStateType,
  action: ReturnType<typeof da.orders.crud.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onSetOrderApiData(
  state: CrudOrderStateType,
  action: ReturnType<typeof da.orders.crud.setOrderApiData>,
) {
  state.data.api = action.payload.order;
  if (action.payload.order) {
    state.data.ui = {
      status: action.payload.order.status,
      buyerName: action.payload.order.buyerName,
      buyerId: action.payload.order.buyerId,
      vendorId: action.payload.order.vendorId,
      vendorName: action.payload.order.vendorName,
      referenceId: action.payload.order.referenceId,
      billDate: action.payload.order.billDate,
      serviceDate: action.payload.order.serviceDate,
      lineItems: action.payload.order.lineItems,
      deduction: {
        amount: action.payload.order.vendorFee,
      },
      isFinanced: action.payload.order.isFinanced,
    };
  }
}

export function onSetOrderUiData(
  state: CrudOrderStateType,
  action: ReturnType<typeof da.orders.crud.setOrderUiData>,
) {
  if (!state.data.ui) {
    state.data.ui = {
      ...uiOrderTemplate,
      [action.payload.key]: action.payload.value,
    };
  } else if (state.data.ui?.status !== "confirmed") {
    if (action.payload.key in state.data.ui) {
      state.data.ui = {
        ...state.data.ui,
        [action.payload.key]: action.payload.value,
      };
    }
  }
}

export function onClearOrderById(state: CrudOrderStateType) {
  state.data.orderById = null;
}

export function onSetOrderById(
  state: CrudOrderStateType,
  action: ReturnType<typeof da.orders.crud.setOrderById>,
) {
  state.data.orderById = action.payload.order;
}

export function onClearUpsertOrder(state: CrudOrderStateType) {
  state.data.ui = null;
  state.data.api = null;
  state.data.orderById = null;
}
