import { createAsyncSlice } from "@pd/redux/types";
import { createReducer } from "@reduxjs/toolkit";
import { AllTeamMembersStateType } from "@pd/layouts/MktplaceDashboard/types/teams";
import da from "../../actions";

export const initAllTeamMembersState = createAsyncSlice({
  team: [],
});

export default function createAllOrdersReducer() {
  return createReducer<AllTeamMembersStateType>(
    initAllTeamMembersState,
    (builder) => {
      builder
        .addCase(da.teams.all.apiFetching, onApiFetching)
        .addCase(da.teams.all.apiError, onApiError)
        .addCase(da.teams.all.apiSuccess, onApiSuccess)
        .addCase(da.teams.all.setAllTeamMembers, onSetAllTeamMembers);
    },
  );
}

export function onApiFetching(
  state: AllTeamMembersStateType,
  action: ReturnType<typeof da.teams.all.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: AllTeamMembersStateType,
  action: ReturnType<typeof da.teams.all.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: AllTeamMembersStateType,
  action: ReturnType<typeof da.teams.all.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onSetAllTeamMembers(
  state: AllTeamMembersStateType,
  action: ReturnType<typeof da.teams.all.setAllTeamMembers>,
) {
  state.data.team = action.payload.team;
}
