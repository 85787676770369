import { Stack, Button, Typography } from "@mui/material";
import ColdLoginSubMsg from "../ColdLoginSubMsg";

type Props = {
  onSetShowEmailSent: (showEmailSent: boolean) => void;
};
export default function EmailSentForm(props: Props) {
  return (
    <Stack gap={1} alignItems="center">
      <Stack gap={1} alignItems="center">
        <Typography variant="h1">Email sent</Typography>
        <Typography variant="body1">
          Check your email for a link from Stitch to login. If {"you're"} having
          trouble logging in, please contact us for help.
        </Typography>
      </Stack>
      <Stack sx={{ width: "100%", mt: 5 }} gap={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.onSetShowEmailSent(false)}
        >
          Try Again
        </Button>
        <Button variant="outlined" color="primary">
          I need help
        </Button>
      </Stack>
      <ColdLoginSubMsg />
    </Stack>
  );
}
