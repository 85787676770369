import { createReducer } from "@reduxjs/toolkit";

import type { CsvUploadOrdersStateType } from "@pd/layouts/MktplaceDashboard/types/csvUpload";
import { createAsyncSlice } from "@pd/redux/types";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";

export const initCsvUploadState = createAsyncSlice({
  filename: "",
  report: null,
});

export default function createCsvUploadReducer() {
  return createReducer<CsvUploadOrdersStateType>(
    initCsvUploadState,
    (builder) => {
      builder
        .addCase(da.orders.csvUpload.apiFetching, onApiFetching)
        .addCase(da.orders.csvUpload.apiError, onApiError)
        .addCase(da.orders.csvUpload.apiSuccess, onApiSuccess)
        .addCase(da.orders.csvUpload.setFileName, onSetFilename)
        .addCase(da.orders.csvUpload.setUploadReport, onSetUploadReport)
        .addCase(da.orders.csvUpload.reset, onReset);
    },
  );
}

export function onApiFetching(
  state: CsvUploadOrdersStateType,
  action: ReturnType<typeof da.orders.csvUpload.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: CsvUploadOrdersStateType,
  action: ReturnType<typeof da.orders.csvUpload.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: CsvUploadOrdersStateType,
  action: ReturnType<typeof da.orders.csvUpload.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onSetFilename(
  state: CsvUploadOrdersStateType,
  action: ReturnType<typeof da.orders.csvUpload.setFileName>,
) {
  state.data.filename = action.payload.fileName;
}

export function onSetUploadReport(
  state: CsvUploadOrdersStateType,
  action: ReturnType<typeof da.orders.csvUpload.setUploadReport>,
) {
  state.data.report = action.payload.report;
  state.data.filename = action.payload.filename;
}

export function onReset() {
  return { ...initCsvUploadState };
}
