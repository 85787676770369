import { Stack, Typography, useTheme } from "@mui/material";
import MiscColors from "@pd/mantis/themes/miscColors";
import { LinkedAcctType } from "../../types";
import getAccountTexts from "../../utils/getAccountTexts";
import PlaidAcctStatusBadge from "./components/StatusBadge";

type Props = {
  account: LinkedAcctType;
  plaidIsReady: boolean;
  tokenFetching: boolean;
  onLinkAccount: (params: { retry: boolean; accountId: string }) => void;
};

export default function LinkedAccount(props: Props) {
  const miscColors = MiscColors();
  const theme = useTheme();
  const { title, subTitle, errorMsg, devTexts } = getAccountTexts(
    props.account,
  );

  if (!props.account.account.id) return null;

  return (
    <Stack
      data-testid="plaidLinkedAccount"
      key={props.account.account.id}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ mb: "10px" }}
    >
      <Stack>
        <Typography variant="body1">{title}</Typography>
        <Typography variant="body1" color={miscColors.greySubtitle}>
          {subTitle}
          <br />
          {process.env.STITCH_ENV === "dev" ? devTexts : ""}
        </Typography>
        {props.account.error ? (
          <Typography
            variant="body1"
            color={theme.palette.error.main}
            sx={{ maxWidth: "90%" }}
          >
            {errorMsg}
          </Typography>
        ) : null}
      </Stack>
      <PlaidAcctStatusBadge
        plaidIsReady={props.plaidIsReady}
        account={props.account}
        tokenFetching={props.tokenFetching}
        onLinkAccount={props.onLinkAccount}
      />
    </Stack>
  );
}
