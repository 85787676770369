import { useTheme } from "@mui/material/styles";
import { Avatar, Box, IconButton, ListItemText, Stack } from "@mui/material";
import menuArrowDown from "@pd/assets/icons/menu-arrow-down.svg";
import userDefaultImg from "@pd/assets/images/users/default.png";

type Props = {
  styles?: object;
  name: string;
  email: string;
  onClick?: () => void;
};
ActorBadge.defaultProps = {
  styles: {},
  onClick: () => {},
};
export default function ActorBadge(props: Props) {
  const theme = useTheme();
  return (
    <Box sx={props.styles}>
      <IconButton
        edge="end"
        aria-label="show-menu"
        sx={{
          height: "100%",
          width: "100%",
          color:
            theme.palette.mode === "dark"
              ? theme.palette.grey[500]
              : theme.palette.grey[900],
          "&:focus": {
            outline: "none",
          },
        }}
        onClick={props.onClick}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-around"
          sx={{ width: "100%" }}
        >
          <Avatar sx={{ w: 40, h: 40, backgroundColor: "#FEE7BA" }}>
            <img src={userDefaultImg} alt="User 1" style={{ width: "40px" }} />
          </Avatar>
          <ListItemText
            primary={props.name}
            secondary={
              props.email.length > 18
                ? `${props.email.substring(0, 18)}...`
                : props.email
            }
            sx={{
              textAlign: "left",
              pl: "10px",
              "& .MuiListItemText-primary": {
                color: "#000000",
                fontSize: "1rem",
                fontWeight: "400",
                textOverflow: "ellipsis",
              },
            }}
          />
          <img
            alt="menu-arrow-down"
            src={menuArrowDown}
            style={{ width: "10px", height: "9px", marginRight: "20px" }}
          />
        </Stack>
      </IconButton>
    </Box>
  );
}
