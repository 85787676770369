import * as mt from "@pd/layouts/BuyerPortal/redux/types/modalTypes";

const { FlowTypes, FlowSubTypes } = mt;
const selectPayMethod: mt.SelectPaymentMethodType = {
  payByBank: FlowTypes.plaidLinking,
  // TODO: Configure the rest below in MVP 2
  payByAchCredit: FlowSubTypes.payByAchDebit,
  payByWire: FlowSubTypes.payByWire,
  payByCard: FlowSubTypes.payByCard,
  payByCheck: FlowSubTypes.payByCheck,
};

const plaidLinking: mt.PlaidLinking = {
  error: FlowTypes.plaidError,
  next: FlowTypes.paymentReview,
};

const plaidError: mt.PlaidErrorType = {
  props: { error: null },
  retry: FlowTypes.selectPayMethod,
};

const paymentReview: mt.PaymentReviewType = {
  pickDate: FlowTypes.paymentCalendar,
  goTo: {
    slug: "/make_payment",
  },
  editPayMethod: FlowTypes.editPayMethod,
  paymentRelinkError: FlowTypes.paymentRelinkError,
  paymentRetryError: FlowTypes.paymentRetryError,
  next: FlowTypes.paymentConfirmed,
  back: FlowTypes.selectPayMethod,
};

const paymentConfirmed: mt.PaymentConfirmedType = {
  autopayReview: FlowTypes.autopayReview,
  goTo: {
    slug: "/make_payment",
  },
};

const paymentCalendar: mt.PaymentCalendarType = {
  goTo: {
    slug: "/make_payment",
  },
  editPayMethod: FlowTypes.editPayMethod,
  next: FlowTypes.paymentConfirmed,
  error: FlowTypes.paymentRetryError,
  relink: FlowTypes.paymentRelinkError,
  back: FlowTypes.paymentReview,
};

const autopayReview: mt.AutoPayReviewType = {
  pickDate: FlowTypes.paymentCalendar,
  editPayMethod: FlowTypes.editPayMethod,
  next: FlowTypes.autopayConfirmed,
};

const paymentRetryError: mt.PaymentRetryErrorType = {
  props: {
    error: null,
  },
  retry: FlowTypes.paymentReview,
};
const paymentRelinkError: mt.PaymentRelinkErrorType = {
  props: {
    error: null,
  },
  retry: FlowTypes.plaidLinking,
};

const autopayConfirmed: mt.AutopayConfirmedType = {
  goTo: {
    slug: "/home",
  },
};

const editPayMethod: mt.EditPayMethodType = {
  props: {
    linkedAccount: null,
  },
  next: FlowTypes.paymentReview,
  back: FlowTypes.paymentReview,
};

const modalTransitionMap: mt.ModalTransitionMapType = {
  [FlowTypes.selectPayMethod]: selectPayMethod, // modal ✅
  [FlowTypes.plaidLinking]: plaidLinking, // modal ✅
  [FlowTypes.plaidError]: plaidError, // modal ✅
  [FlowTypes.paymentReview]: paymentReview, // modal ✅
  [FlowTypes.paymentConfirmed]: paymentConfirmed, // modal ✅
  [FlowTypes.paymentCalendar]: paymentCalendar, // modal ✅
  [FlowTypes.paymentRetryError]: paymentRetryError,
  [FlowTypes.paymentRelinkError]: paymentRelinkError,
  [FlowTypes.editPayMethod]: editPayMethod, // modal ✅
  [FlowTypes.autopayReview]: autopayReview, // modal ✅
  [FlowTypes.autopayConfirmed]: autopayConfirmed, // modal ✅
};
export default modalTransitionMap;
