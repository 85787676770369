import { Link } from "react-router-dom";

// material-ui
import { AppBar, Stack, Toolbar, useTheme } from "@mui/material";

// types
import ActorBadge from "./ActorBadge";
import WhiteLabelLogo from "./WhiteLabelLogo";

export default function PortalHeader() {
  const theme = useTheme();

  return (
    <AppBar
      sx={{
        bgcolor: "white",
        color: theme.palette.text.primary,
        boxShadow: "none",
      }}
    >
      <Toolbar>
        <Stack
          sx={{
            width: "100%",
            "& .header-link": {
              px: 1,
              "&:hover": { color: theme.palette.primary.main },
            },
          }}
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <Stack direction="row" alignItems="center">
            <WhiteLabelLogo />
            <Stack sx={{ pl: 5 }} direction="row" gap={3}>
              <Link to="/portal">Home</Link>
              <Link to="/portal/invoices">Invoices</Link>
              <Link to="/portal/settings">Settings</Link>
              <Link to="/portal/account">Account</Link>
              <Link to="/auth/login/email">Login</Link>
              <Link to="/auth/link-expired">Link Expired</Link>
            </Stack>
          </Stack>
          <ActorBadge
            name="Tony Stark"
            email="tony@gmail.com"
            styles={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: theme.palette.grey.A100,
              width: "250px",
            }}
          />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
