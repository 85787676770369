import { useState } from "react";
import {
  Modal,
  Box,
  ButtonBase,
  Fade,
  Stack,
  Backdrop,
  Typography,
} from "@mui/material";
import {
  ArrowLeftOutlined,
  CloseOutlined,
  LoadingOutlined,
  // CalendarOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FlowTypes, FlowSubTypes } from "../../redux/types/modalTypes";
import { modalStyles } from "../constants/styles";

type Props = {
  onClose: () => void;
  onOpen: (caller: FlowTypes, actionTaken: FlowTypes | FlowSubTypes) => void;
};
export default function PaymentCalendar(props: Props) {
  const [open, setOpen] = useState(true);
  const [date, setDate] = useState<Date | null>(new Date());
  const minDate = new Date();
  const maxDate = new Date();
  maxDate.setMonth(maxDate.getMonth() + 1);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const handleBack = () => {
    props.onOpen(FlowTypes.paymentCalendar, FlowSubTypes.back);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}
    >
      <Fade in>
        <Stack gap={2} sx={modalStyles} justifyContent="space-between">
          <Stack direction="row" justifyContent="space-between">
            <ButtonBase onClick={handleBack}>
              <ArrowLeftOutlined />
            </ButtonBase>
            <Typography variant="body1">Review Payment</Typography>
            <ButtonBase onClick={handleClose}>
              <CloseOutlined />
            </ButtonBase>
          </Stack>
          <Stack gap={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateCalendar
                value={date}
                onChange={(newDate: Date | null) => setDate(newDate)}
                minDate={minDate}
                maxDate={maxDate}
              />
            </LocalizationProvider>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body2">Pay On</Typography>
              <Typography variant="body2">
                {date ? date.toDateString() : <LoadingOutlined />}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body2">Total</Typography>
              <Stack gap={2} direction="row" alignItems="center">
                <Box>
                  <Typography variant="body2">{`$ ${100}.00`}</Typography>
                  <Typography variant="body2">4 invoices</Typography>
                </Box>
                <EditOutlined />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body2">Payment Method</Typography>
              <Stack gap={2} direction="row" alignItems="center">
                <Box>
                  <Typography variant="body2">Bank of America</Typography>
                  <Typography variant="body2">Checking</Typography>
                </Box>
                <EditOutlined />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Fade>
    </Modal>
  );
}
