import { SERVER_ERROR_TYPE } from "./constants";

export default class ServerApiError extends Error {
  type: string;

  status: number;

  constructor(m?: string) {
    super(m || "Server error");
    this.type = SERVER_ERROR_TYPE;
    this.status = 500;
  }
}
