import PlaidLinkModal from "@pd/components/PlaidLink/LinkByModal";
import {
  FlowTypes,
  FlowSubTypes,
  ModalStringErrorPropsType,
  EditPayMethodPropType,
} from "../../redux/types/modalTypes";

type Props = {
  onClose: () => void;
  onOpen: (
    caller: FlowTypes,
    actionTaken: FlowTypes | FlowSubTypes,
    props?: ModalStringErrorPropsType | EditPayMethodPropType,
  ) => void;
};
export default function PlaidModalContainer(props: Props) {
  // TODO: Finish building this when building Buyer Portal
  const handleOpenPaymentReview = () => {
    props.onOpen(FlowTypes.plaidLinking, FlowSubTypes.next);
  };
  const handleOpenLinkError = (error?: string) => {
    if (error) {
      props.onOpen(FlowTypes.plaidLinking, FlowTypes.plaidError, {
        [FlowSubTypes.error]: error,
      });
    } else {
      props.onOpen(FlowTypes.plaidLinking, FlowTypes.plaidError);
    }
  };
  const handleOpenRelinkError = () => {
    props.onOpen(FlowTypes.plaidLinking, FlowSubTypes.payByBank);
  };
  const handleClose = () => {
    props.onClose();
  };

  return (
    <PlaidLinkModal
      onClose={handleClose}
      onLinkError={handleOpenLinkError}
      onRelinkError={handleOpenRelinkError}
      onShowPaymentReview={handleOpenPaymentReview}
    />
  );
}
