import { takeLatest, put, takeLeading, all } from "redux-saga/effects";
import modalActions from "../actions/modalActions";
import modalTransitionMap from "../../modals/constants/modalFlows";
import {
  FlowSubTypes,
  isSelectPaymentMethodType,
  isSelectPaymentMethodSubType,
  isPlaidLinkingType,
  isPaymentRelinkErrorType,
  isPlaidErrorType,
  isAutoPayReviewType,
  isAutopayConfirmedType,
  isEditPayMethodType,
  isPaymentCalendarType,
  isPaymentConfirmedType,
  isPaymentReviewType,
  isPaymentRetryErrorType,
  isPaymentCalendarSubType,
  isPaymentRetryErrorSubType,
  isEditPayMethodSubType,
  isPlaidLinkingSubType,
  isPaymentRelinkErrorSubType,
  isPlaidErrorSubType,
  isPaymentReviewSubType,
  isPaymentConfirmedSubType,
  isAutoPayReviewSubType,
  isAutopayConfirmedSubType,
  isAutopayReviewPropType,
  isPaymentReviewPropType,
  isGoToSlugType,
  NextFlowTypes,
  FlowTypes,
} from "../types/modalTypes";

export default function* modalSaga() {
  yield all([
    takeLeading(modalActions.confirmPayment.toString(), onConfirmPayment),
    takeLatest(modalActions.transition.toString(), onTransition),
  ]);
}

function* onTransition(action: ReturnType<typeof modalActions.transition>) {
  const { caller, actionTaken, props } = action.payload;
  const currentFlow = modalTransitionMap[caller];
  if (!currentFlow) {
    console.error("No current modal path found for caller: ", caller);
    return;
  }
  let nextFlow: NextFlowTypes;
  if (actionTaken === FlowSubTypes.goTo && FlowSubTypes.goTo in currentFlow) {
    yield put(modalActions.changeRoute(currentFlow[actionTaken].slug));
  } else if (
    isSelectPaymentMethodType(currentFlow) &&
    isSelectPaymentMethodSubType(actionTaken)
  ) {
    nextFlow = currentFlow[actionTaken];
  } else if (
    isPlaidLinkingType(currentFlow) &&
    isPlaidLinkingSubType(actionTaken)
  ) {
    nextFlow = currentFlow[actionTaken];
  } else if (
    isPaymentRelinkErrorType(currentFlow) &&
    isPaymentRelinkErrorSubType(actionTaken)
  ) {
    nextFlow = currentFlow[actionTaken];
  } else if (
    isPlaidErrorType(currentFlow) &&
    isPlaidErrorSubType(actionTaken)
  ) {
    nextFlow = currentFlow[actionTaken];
  } else if (
    isPaymentReviewType(currentFlow) &&
    isPaymentReviewSubType(actionTaken)
  ) {
    nextFlow = currentFlow[actionTaken];
  } else if (
    isPaymentConfirmedType(currentFlow) &&
    isPaymentConfirmedSubType(actionTaken)
  ) {
    nextFlow = currentFlow[actionTaken];
  } else if (
    isPaymentCalendarType(currentFlow) &&
    isPaymentCalendarSubType(actionTaken)
  ) {
    nextFlow = currentFlow[actionTaken];
  } else if (
    isAutoPayReviewType(currentFlow) &&
    isAutoPayReviewSubType(actionTaken)
  ) {
    nextFlow = currentFlow[actionTaken];
  } else if (
    isPaymentRetryErrorType(currentFlow) &&
    isPaymentRetryErrorSubType(actionTaken)
  ) {
    nextFlow = currentFlow[actionTaken];
  } else if (
    isAutopayConfirmedType(currentFlow) &&
    isAutopayConfirmedSubType(actionTaken)
  ) {
    nextFlow = currentFlow[actionTaken];
  } else if (
    isEditPayMethodType(currentFlow) &&
    isEditPayMethodSubType(actionTaken)
  ) {
    if (actionTaken === FlowSubTypes.back) {
      if (isAutopayReviewPropType(props)) {
        nextFlow = FlowTypes.autopayReview;
      } else if (isPaymentReviewPropType(props)) {
        nextFlow = FlowTypes.paymentReview;
      }
    } else {
      nextFlow = currentFlow[actionTaken];
    }
  }
  if (nextFlow) {
    if (isGoToSlugType(nextFlow)) {
      yield put(modalActions.changeRoute(nextFlow.slug));
    }
    yield put(modalActions.open(nextFlow, props));
  } else {
    console.error("No next modal path found for caller: ", caller);
  }
}

function* onConfirmPayment() {
  /**
   * TODO: Finish building onConfirmPayment saga!
   * 1. Move this logic into the onTransition saga
   * 2. Add a new FowSubType.confirmPayment to the paymentReviw modal flow.
   * 3. The saga should call the API to confirm the payment, then transition to the paymentConfirmed modal or either of the payment error modals:
   *  - paymentRetryError modal.
   *  - paymentRelinkError modal.
   */
  alert("TODO: Finish building onConfirmPayment saga!");
  yield put(modalActions.close());
}
