import { createAsyncSlice } from "@pd/redux/types";
import { createReducer } from "@reduxjs/toolkit";
import { PingPongStateType } from "@pd/layouts/MktplaceDashboard/types/pingPong";
import da from "../../actions";

export const initPingPongState: PingPongStateType = createAsyncSlice({
  status: "",
});

export default function createPingPongReducer() {
  return createReducer<PingPongStateType>(initPingPongState, (builder) => {
    builder
      .addCase(da.pingPong.apiFetching, onApiFetching)
      .addCase(da.pingPong.apiError, onApiError)
      .addCase(da.pingPong.apiSuccess, onApiSuccess)
      .addCase(da.pingPong.setPingCheck, onSetPingCheck);
  });
}

export function onApiFetching(
  state: PingPongStateType,
  action: ReturnType<typeof da.pingPong.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: PingPongStateType,
  action: ReturnType<typeof da.pingPong.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: PingPongStateType,
  action: ReturnType<typeof da.pingPong.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onSetPingCheck(
  state: PingPongStateType,
  action: ReturnType<typeof da.pingPong.setPingCheck>,
) {
  state.data.status = action.payload.status;
}
