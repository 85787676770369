import { createAction } from "@reduxjs/toolkit";
import { ErrorPayload } from "@pd/api/utils/safeFetch";

const actions = {
  apiFetching: createAction(
    "plaidLink/tokens/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiSuccess: createAction(
    "plaidLink/tokens/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  apiError: createAction(
    "plaidLink/tokens/apiError",
    (error: ErrorPayload) => ({
      payload: { error },
    }),
  ),
  getLinkToken: createAction("plaidLink/tokens/getLinkToken"),
  setLinkToken: createAction(
    "plaidLink/tokens/setLinkToken",
    (linkToken: string) => ({ payload: { linkToken } }),
  ),
  getAccessToken: createAction(
    "plaidLink/tokens/getAccessToken",
    (publicToken: string, accountIds: string[]) => ({
      payload: { publicToken, accountIds },
    }),
  ),
  clearTokens: createAction("plaidLink/tokens/clearTokens"),
};
export default actions;
