import { createReducer } from "@reduxjs/toolkit";
import modalActions from "../actions/modalActions";

import { ManualRouteStateType } from "../types/manualRouteTypes";

export const initialState = {
  newRoute: "",
};

const routeReducer = createReducer<ManualRouteStateType>(
  initialState,
  (builder) => {
    builder.addCase(modalActions.changeRoute, onChangeRoute);
  },
);
export default routeReducer;

function onChangeRoute(
  state: ManualRouteStateType,
  action: ReturnType<typeof modalActions.changeRoute>,
) {
  const { route } = action.payload;
  state.newRoute = route;
}
