import { createSelector } from "@reduxjs/toolkit";
import { AppStateType } from "@pd/redux/store/reducers";
import { OrderCreatedDbType, OrderDetailDbType } from "@pd/redux/types/dbTypes";
import { CreateOrderFormTypes } from "@pd/layouts/MktplaceDashboard/pages/Orders/components/CreateEditOrder/types";
import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { isDashboardState } from "../../../types";

export const selectCrudOrderFetching = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.orders.crudOrder.fetching;
    }
    return false;
  },
);

export const selectCrudOrderError = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): ErrorPayload => {
    if (isDashboardState(state)) {
      return state.dashboard.orders.crudOrder.error;
    }
    return {
      message: "",
      status: 0,
    };
  },
);

export const selectCrudOrderSuccess = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.orders.crudOrder.success;
    }
    return false;
  },
);

export const selectUiOrder = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): CreateOrderFormTypes | null => {
    if (isDashboardState(state)) {
      return state.dashboard.orders.crudOrder.data.ui;
    }
    return null;
  },
);

export const selectApiOrder = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): OrderCreatedDbType | null => {
    if (isDashboardState(state)) {
      return state.dashboard.orders.crudOrder.data.api;
    }
    return null;
  },
);

export const selectOrderById = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): OrderDetailDbType | null => {
    if (isDashboardState(state)) {
      return state.dashboard.orders.crudOrder.data.orderById;
    }
    return null;
  },
);
