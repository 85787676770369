import dev from "./dev";
import staging from "./staging";
import sandbox from "./sandbox";
import prod from "./prod";

let env = dev;
switch (process.env.STITCH_ENV) {
  case "dev":
    env = dev;
    break;
  case "staging":
    env = staging;
    break;
  case "sandbox":
    env = sandbox;
    break;
  case "prod":
    env = prod;
    break;
  default:
    env = dev;
    break;
}

const _env = env;
export default _env;
const { endpoints } = env;
export { endpoints };
