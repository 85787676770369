import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { AsyncStateType } from "@pd/redux/types";
import { PlaidLinkError } from "react-plaid-link";

export type { PlaidLinkOnSuccessMetadata } from "react-plaid-link";

// Define the individual types based on the provided image
export type LinkAccountType = {
  id: string;
  name: string;
  type: string;
  subtype: string;
  verificationStatus: string;
  mask: string;
};

export type LinkInstitutionType = {
  name: string;
  id: string;
};

export type LinkErrorType = {
  displayMessage: string;
  errorCode: string;
  errorMessage: string;
  errorType: string;
};

export type LinkedAcctType = {
  account: LinkAccountType;
  institution: LinkInstitutionType;
  error: LinkErrorType;
};

export type PlaidTokenStateType = AsyncStateType & {
  data: {
    linkToken: string;
    publicToken: string;
  };
};
export type PlaidLinkedAcctsStateType = AsyncStateType & {
  data: {
    accounts: LinkedAcctType[];
    linkAccountId: string;
  };
};

// Define the overall PlaidLinkStateType
export type PlaidLinkStateType = {
  tokens: PlaidTokenStateType;
  linkedAccounts: PlaidLinkedAcctsStateType;
};

export function isPlaidLinkError(
  error: string | PlaidLinkError | ErrorPayload,
): error is PlaidLinkError {
  return (
    typeof error === "object" &&
    error !== null &&
    "error_type" in error &&
    "error_code" in error &&
    "error_message" in error &&
    "display_message" in error
  );
}
