import { ThemeMode } from "@pd/mantis/types/config";

export type MiscColorsType = {
  [key: string]: string;
};
// @ts-expect-error - Defined but never used;
const MiscColors = (mode?: ThemeMode): MiscColorsType => ({
  // eslint-disable-line
  // misc
  primary: "#000000",
  secondary: "#6F6F6F",
  orangeText: "#C8A885",
  orangeBg: "#FDF3E7",
  greenBg: "#ECFDF9",
  greenText: "#6AA597",
  blueBg: "#F2F8FC",
  blueText: "#6598BF",
  greySubtitle: "#828282",
  statusRedBg: "#FCB8B4",
  statusOrangeBg: "#F8DAB6",
  statusGreenBg: "#C9E8E1",
  statusYellowBg: "#FFF6C4",
  statusPurpleBg: "#DEDBEF",
  statusVioletBg: "#D8EAF9",
  statusGrayBg: "#D9D9D9",
  iconBlueBg: "#A4CDED",
  paginationBlue: "#1890FF",
});
export default MiscColors;
