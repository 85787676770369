import errors from "@pd/utils/errors";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";
import { HTMLAttributeReferrerPolicy } from "react";
import transformSnakeToCamel from "../transformSnakeToCamel";

export type { ErrorPayload, DataResType, ErrorResType } from "./types";

export const extractError = async (response: Response) => {
  if (response.status === 401) {
    const err = new errors.UnauthorizedApiError();
    err.status = response.status;
    throw err;
  }

  if (response.status === 403) {
    const err = new errors.ExpiredTokenApiError();
    err.status = response.status;
    throw err;
  }

  if (response.status >= 500) {
    const data = await parseResponse(response);
    const err = new errors.ServerApiError(data.message);
    err.status = response.status;
    throw err;
  }

  if (!response.ok) {
    const data = await parseResponse(response);
    if (typeof data === "object") {
      if ("details" in data) {
        const details = transformSnakeToCamel(data.details);
        const errorKey = Object.keys(details)[0];
        const errorMsg = `${errorKey}: ${details[errorKey]}`;
        const err = new errors.NonCriticalApiError(errorMsg);
        err.status = MANUAL_ERROR_CODE;
        throw err;
      } else if ("errors" in data) {
        const err = new errors.CsvUploadApiError(transformSnakeToCamel(data));
        throw err;
      } else {
        const err = new errors.NonCriticalApiError(data.error);
        err.message = data.message;
        err.status = response.status;
        throw err;
      }
    } else {
      const err = new errors.NonCriticalApiError(response.statusText);
      err.status = response.status;
      throw err;
    }
  }
  return response;
};

function createHeaders(headers: HeadersInit = {}) {
  return new Headers({
    accept: "*/*",
    ...headers,
  });
}

export async function getResult(res: Response) {
  const contentType =
    res.headers.get("Content-Type") || res.headers.get("content-type");
  if (contentType && contentType.includes("application/json")) {
    const response = await res.json();
    const result = transformSnakeToCamel(response);
    return result;
  }
  return res.text();
}

type SafeFetchArgs = {
  method?: string;
  body?: string | FormData;
  headers?: HeadersInit;
  mode?: RequestMode;
  credentials?: RequestCredentials;
  referrer?: string;
  referrerPolicy?: HTMLAttributeReferrerPolicy;
};

type SafeFetchCallback = (response: Response) => any;

export default async function safeFetch(
  url: string,
  args: SafeFetchArgs = {},
  cb: SafeFetchCallback = (r) => {
    if (typeof r === "object" && r !== null && "data" in r) {
      return r;
    }
    return { data: r };
  },
) {
  const config = {
    ...args,
    headers: createHeaders({
      ...args.headers,
    }),
  };

  return fetch(url, config)
    .then(extractError)
    .then(getResult)
    .then(cb)
    .catch((err) => ({
      error: {
        message: err.toString(),
        status: err.status,
        data: err?.data,
      },
    }))
    .finally(() => {
      console.info(`[API] ${config.method || "GET"} ${url}`); // eslint-disable-line no-restricted-syntax
    });
}

async function parseResponse(res: Response) {
  const contentType =
    res.headers.get("Content-Type") || res.headers.get("content-type");
  if (contentType && contentType.includes("application/json")) {
    return res.json();
  }
  return res.text();
}
