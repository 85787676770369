import { createAction } from "@reduxjs/toolkit";
import { ErrorPayload } from "@pd/api/utils/safeFetch";
import type {
  TableFilterByDateType,
  TableFilterMiscType,
  TableDataSrcType,
  TableFilterType,
} from "../../../types/tables";

const tableActions = {
  apiFetching: createAction(
    "dashboard/table/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction("dashboard/table/apiError", (error: ErrorPayload) => ({
    payload: { error },
  })),
  apiSuccess: createAction(
    "dashboard/table/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  setTableMiscFilter: createAction(
    "dashboard/table/setTableMiscFilter",
    (filter: TableFilterMiscType) => ({
      payload: filter,
    }),
  ),
  setTableDateFilter: createAction(
    "dashboard/table/setTableDateFilter",
    (filter: TableFilterByDateType) => ({
      payload: filter,
    }),
  ),
  setTableSearchFilter: createAction(
    "dashboard/table/setTableSearchFilter",
    (value: string) => ({
      payload: { value },
    }),
  ),
  setTableFilterError: createAction(
    "dashboard/table/setTableFilterError",
    (error: string) => ({
      payload: { error },
    }),
  ),
  setTableDataSrc: createAction(
    "dashboard/table/setTableDataSrc",
    (dataSrc: TableDataSrcType) => ({
      payload: { dataSrc },
    }),
  ),
  setTablePagination: createAction(
    "dashboard/table/setTablePagination",
    (pagination: {
      limit: number;
      offset: number;
      sortDir: string;
      sortKey: string;
      total?: number;
    }) => ({
      payload: pagination,
    }),
  ),
  getTableData: createAction(
    "dashboard/table/getTableData",
    (payload: { dataSrc: TableDataSrcType }) => ({ payload }),
  ),
  getTableDataOnFilterChange: createAction(
    "dashboard/table/getTableDataOnFilterChange",
    (payload: {
      changeSrc: string;
      dataSrc: TableDataSrcType;
      filter: TableFilterType;
    }) => ({
      payload,
    }),
  ),
  clearTableFilters: createAction("dashboard/table/clearTableFilters"),
  setSortDir: createAction("dashboard/table/setSortDir", (sortDir: string) => ({
    payload: { sortDir },
  })),
  setSortKey: createAction("dashboard/table/setSortKey", (sortKey: string) => ({
    payload: { sortKey },
  })),
  setSortEnabled: createAction(
    "dashboard/table/setSortEnabled",
    (sortEnabled: boolean) => ({
      payload: { sortEnabled },
    }),
  ),
};
export default tableActions;
