import { createAction } from "@reduxjs/toolkit";

const dashboardRouteActions = {
  redirect: createAction("dashboard/routes/setRoute", (route: string) => ({
    payload: { route },
  })),
  clearNextRoute: createAction("dashboard/routes/clearRoute"),
};

export default dashboardRouteActions;
