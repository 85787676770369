import { createAction } from "@reduxjs/toolkit";
import { BuyerPortalStateType } from "../types";

const actions = {
  fetching: createAction(
    "dashboard/fetching",
    (fetching: boolean, stateKey: keyof BuyerPortalStateType) => ({
      payload: { fetching, stateKey },
    }),
  ),
  apiError: createAction(
    "dashboard/apiError",
    (error: string, stateKey: keyof BuyerPortalStateType) => ({
      payload: { error, stateKey },
    }),
  ),
};
export default actions;
