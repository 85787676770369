import { Stack, Typography, ButtonBase, useTheme } from "@mui/material";
import { CloseOutlined } from "@ant-design/icons";

type Props = {
  title: string;
  useBorderBottom?: boolean;
  onClose?: () => void;
};
export default function ModalTitle({
  useBorderBottom = true,
  ...props
}: Props) {
  const theme = useTheme();

  return (
    <Stack
      justifyContent="center"
      direction="row"
      sx={{
        p: 2,
        borderBottom: useBorderBottom
          ? `1px solid ${theme.palette.secondary.light}`
          : "none",
      }}
    >
      <Typography
        variant="body1"
        sx={{
          fontFamily: "CircularBold",
          flexGrow: 1,
          textAlign: "center",
        }}
      >
        {props.title}
      </Typography>
      {props.onClose ? (
        <ButtonBase
          onClick={() => (props.onClose ? props.onClose() : () => {})}
        >
          <CloseOutlined style={{ fontSize: "21px" }} />
        </ButtonBase>
      ) : null}
    </Stack>
  );
}
