import { AllOrderStateType } from "./allOrders";
import { CrudOrderStateType } from "./crudOrder";
import { CsvUploadOrdersStateType } from "./csvUpload";
import { AllBuyerStateType } from "./allBuyers";
import { CrudBuyerStateType } from "./crudBuyer";
import { TableStateType } from "./tables";
import { TransfersStateType } from "./transfers";
import { VendorsStateType } from "./vendors";
import { PingPongStateType } from "./pingPong";
import { RouteStateType } from "./routes";
import { SettingsStateType } from "./settings";
import { TeamMembersStateType } from "./teams";

export type DashboardStateType = {
  pingPong: PingPongStateType;
  buyers: {
    allBuyers: AllBuyerStateType;
    crudBuyer: CrudBuyerStateType;
  };
  orders: {
    allOrders: AllOrderStateType;
    crudOrder: CrudOrderStateType;
    csvUpload: CsvUploadOrdersStateType;
  };
  transfers: TransfersStateType;
  vendors: VendorsStateType;
  table: TableStateType;
  routes: RouteStateType;
  settings: SettingsStateType;
  teams: TeamMembersStateType;
};

// NOTE: Used in dashboard selectors to verify state shape.
export function isDashboardState(
  state: any,
): state is { dashboard: DashboardStateType } {
  return "dashboard" in state;
}
