import { createAsyncSlice } from "@pd/redux/types";
import { createReducer } from "@reduxjs/toolkit";
import { CrudTeamMembersStateType } from "@pd/layouts/MktplaceDashboard/types/teams";
import da from "../../actions";

export const initAllTeamMembersState = createAsyncSlice({});

export default function createAllOrdersReducer() {
  return createReducer<CrudTeamMembersStateType>(
    initAllTeamMembersState,
    (builder) => {
      builder
        .addCase(da.teams.crud.apiFetching, onApiFetching)
        .addCase(da.teams.crud.apiError, onApiError)
        .addCase(da.teams.crud.apiSuccess, onApiSuccess);
    },
  );
}

export function onApiFetching(
  state: CrudTeamMembersStateType,
  action: ReturnType<typeof da.teams.crud.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: CrudTeamMembersStateType,
  action: ReturnType<typeof da.teams.crud.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: CrudTeamMembersStateType,
  action: ReturnType<typeof da.teams.crud.apiSuccess>,
) {
  state.success = action.payload.success;
}
