import { createReducer } from "@reduxjs/toolkit";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import { createAsyncSlice } from "@pd/redux/types";
import { SettingsStateType } from "@pd/layouts/MktplaceDashboard/types/settings";

export const initialSettingsState = createAsyncSlice({
  apiKeys: [],
});

export default function createSettingsReducer() {
  return createReducer<SettingsStateType>(initialSettingsState, (builder) =>
    builder
      .addCase(da.settings.setApiKeys, onSetApiKeys)
      .addCase(da.settings.apiFetching, onApiFetching)
      .addCase(da.settings.apiSuccess, onApiSuccess)
      .addCase(da.settings.apiError, onApiError),
  );
}

function onSetApiKeys(
  state: SettingsStateType,
  action: ReturnType<typeof da.settings.setApiKeys>,
) {
  state.data.apiKeys = action.payload.keys;
}

function onApiFetching(
  state: SettingsStateType,
  action: ReturnType<typeof da.settings.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

function onApiSuccess(state: SettingsStateType) {
  state.success = true;
}

function onApiError(
  state: SettingsStateType,
  action: ReturnType<typeof da.settings.apiError>,
) {
  state.error = action.payload.error;
}
