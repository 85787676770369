import { Button, Stack, Typography } from "@mui/material";
import MiscColors from "@pd/mantis/themes/miscColors";
import { useAppSelector } from "@pd/redux/store";
import LinkedAccount from "./components/LinkedAccount";
import {
  selectAcctApiError,
  selectLinkedAcctDetails,
  selectTokensApiError,
  selectTokensApiFetching,
} from "./redux/selectors";

type Props = {
  onRemount: () => void;
  plaidReady: boolean;
  startPlaidLink: () => void;
};

function PlaidLinkButton(props: Props) {
  const miscColors = MiscColors();
  const tokenApiError = useAppSelector(selectTokensApiError);
  const tokenApiFetching = useAppSelector(selectTokensApiFetching);

  const acctApiError = useAppSelector(selectAcctApiError);
  const linkedAccount = useAppSelector(selectLinkedAcctDetails);

  const hasApiError = tokenApiError.message || acctApiError.message;

  return (
    <Stack sx={{ height: "100%", minHeight: "40px" }}>
      <Stack
        data-testid="linkAccountBtn"
        sx={{ width: "100%" }}
        alignItems="flex-start"
      >
        <Button
          variant="text"
          sx={{
            display: linkedAccount?.account?.id ? "none" : "block",
            color: miscColors.blueText,
            fontSize: "16px",
          }}
          onClick={() => {
            props.onRemount();
            props.startPlaidLink();
          }}
        >
          Link Account
        </Button>
      </Stack>
      <LinkedAccount
        key={linkedAccount.account.id}
        account={linkedAccount}
        onLinkAccount={props.startPlaidLink}
        tokenFetching={tokenApiFetching}
        plaidIsReady={props.plaidReady}
      />
      <Typography
        variant="body2"
        sx={{
          display: hasApiError ? "block" : "none",
        }}
        color="error"
      >
        {tokenApiError.message || acctApiError.message}
      </Typography>
    </Stack>
  );
}

export default PlaidLinkButton;
