import { ZoneContextManager } from "@opentelemetry/context-zone";
import { BatchSpanProcessor } from "@opentelemetry/sdk-trace-base";
import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import { Resource } from "@opentelemetry/resources";
import {
  SEMRESATTRS_SERVICE_NAME,
  SEMRESATTRS_TELEMETRY_SDK_LANGUAGE,
  TELEMETRYSDKLANGUAGEVALUES_WEBJS,
} from "@opentelemetry/semantic-conventions";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import isDevEnv from "@pd/utils/envCheck";
import { trace, Tracer, SpanStatusCode } from "@opentelemetry/api";
import { ErrorPayload } from "@pd/api/utils/safeFetch";

type SessionAttrsType = {
  userId: string;
  isLoggedIn: boolean;
};

export class Tracing {
  TRACER_ID: string;

  sessionAttrs: SessionAttrsType;

  tracer: Tracer;

  ids: Set<string> = new Set();

  constructor() {
    this.TRACER_ID = `FE-${process.env.STITCH_ENV}_portal-dashboard`;
    this.sessionAttrs = { userId: "", isLoggedIn: false };
    this.buildTracer();
    this.tracer = trace.getTracer(this.TRACER_ID);
  }

  getTracerId = (): string => this.TRACER_ID;

  getTracer = (): Tracer => this.tracer;

  setSessionAttrs = (userId: string, isLoggedIn: boolean) => {
    this.sessionAttrs = { userId, isLoggedIn };
  };

  private buildTracer = () => {
    if (isDevEnv()) return;
    const resource = new Resource({
      [SEMRESATTRS_SERVICE_NAME]: this.TRACER_ID,
      [SEMRESATTRS_TELEMETRY_SDK_LANGUAGE]: TELEMETRYSDKLANGUAGEVALUES_WEBJS,
    });
    const provider = new WebTracerProvider({ resource });
    const spanProcessor = new BatchSpanProcessor(
      new OTLPTraceExporter({
        url: "https://ingest.us.signoz.cloud:443/v1/traces",
        headers: {
          "signoz-access-token": process.env.SIGNOZ_INGESTION_KEY,
        },
      }),
    );
    provider.addSpanProcessor(spanProcessor);
    provider.register({
      contextManager: new ZoneContextManager(),
    });
  };

  captureException = (error: Error | ErrorPayload, _id: string) => {
    if (isDevEnv()) return;
    const id = `exception@${_id}`;
    const span = this.tracer.startSpan(id);
    span.setStatus({ code: SpanStatusCode.ERROR, message: error.message });
    if (this.sessionAttrs.userId) {
      span.setAttribute("userId", this.sessionAttrs.userId || "unknown");
    }
    if (this.sessionAttrs.isLoggedIn) {
      span.setAttribute("isLoggedIn", this.sessionAttrs.isLoggedIn || false);
    }
    span.setAttribute("env", process.env.STITCH_ENV || "unknown");
    span.setAttribute("app", process.env.STITCH_APP || "unknown");
    span.recordException(error);
    span.end();
  };
}

const tracer = new Tracing();
export default tracer;
