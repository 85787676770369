import { createSelector } from "@reduxjs/toolkit";
import { AppStateType } from "@pd/redux/store/reducers";
import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { SortDirType } from "@pd/utils/stableSort";
import { isDashboardState } from "../../../types";
import { TableDataSrcType } from "../../../types/tables";

export const selectTableFilters = createSelector(
  (state: AppStateType) => state,
  (state) => {
    if (isDashboardState(state)) {
      return {
        miscFilter: state.dashboard.table.data.miscFilter,
        dateFilter: state.dashboard.table.data.dateFilter,
        search: state.dashboard.table.data.search,
        pagination: state.dashboard.table.data.pagination,
      };
    }
    return {
      miscFilter: {
        type: "",
        value: "",
      },
      dateFilter: {
        startDate: "",
        endDate: "",
      },
      search: {
        value: "",
      },
      pagination: {
        totalRows: 0,
        limit: 0,
        offset: 0,
        sortDir: "",
        sortKey: "",
      },
    };
  },
);

export const selectHasTableFilters = createSelector(
  (state: AppStateType) => state,
  (state): boolean => {
    let hasFilters = false;
    if (isDashboardState(state)) {
      const { miscFilter, dateFilter, search, pagination } =
        state.dashboard.table.data;
      hasFilters = [
        miscFilter.value,
        dateFilter.startDate || dateFilter.endDate,
        search.value,
        pagination.sortKey,
      ].some(Boolean);
    }
    return hasFilters;
  },
);

export const selectTableDataSrc = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): TableDataSrcType => {
    if (isDashboardState(state)) {
      return state.dashboard.table.data.dataSrc;
    }
    return undefined;
  },
);

export const selectTableFetching = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.table.fetching;
    }
    return false;
  },
);

export const selectTableError = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): ErrorPayload => {
    if (isDashboardState(state)) {
      return state.dashboard.table.error;
    }
    return {
      message: "",
      status: 0,
    };
  },
);

export const selectTableSuccess = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.table.success;
    }
    return false;
  },
);

export const selectTableTotalRows = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): number => {
    if (isDashboardState(state)) {
      return state.dashboard.table.data.pagination.total;
    }
    return 0;
  },
);

export const selectTableLimit = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): number => {
    if (isDashboardState(state)) {
      return state.dashboard.table.data.pagination.limit;
    }
    return 0;
  },
);

export const selectTableOffset = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): number => {
    if (isDashboardState(state)) {
      return state.dashboard.table.data.pagination.offset;
    }
    return 0;
  },
);

export const selectSortDir = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): SortDirType => {
    if (isDashboardState(state)) {
      const { sortDir } = state.dashboard.table.data.pagination;
      return sortDir as SortDirType;
    }
    return "asc";
  },
);

export const selectSortKey = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): string => {
    if (isDashboardState(state)) {
      return state.dashboard.table.data.pagination.sortKey;
    }
    return "";
  },
);

export const selectSortEnabled = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.table.data.pagination.sortEnabled;
    }
    return false;
  },
);
