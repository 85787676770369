export default function isDevEnv() {
  return process.env.STITCH_ENV === "dev";
}

export function isStagingEnv() {
  return process.env.STITCH_ENV === "staging";
}

export function isProdEnv() {
  return process.env.STITCH_ENV === "production";
}

export function isSandboxEnv() {
  return process.env.STITCH_ENV === "sandbox";
}

export function isTestEnv() {
  return [isDevEnv(), isStagingEnv(), isSandboxEnv()].some(Boolean);
}
