import { ReactElement, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "@pd/redux/store";
import { selectLoggedIn } from "@pd/redux/selectors/auth";
import ErrorBoundary from "@pd/components/ErrorBoundary";

function AuthGuard({ children }: { children: ReactElement | null }) {
  const loggedIn = useAppSelector(selectLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!loggedIn) {
      navigate("/auth/login/email", {
        state: {
          from: location.pathname,
        },
        replace: true,
      });
    }
  }, [loggedIn, navigate, location]);

  return <ErrorBoundary>{children}</ErrorBoundary>;
}

export default AuthGuard;
