import { useState } from "react";
import { Modal, Button, Fade, Stack, Backdrop } from "@mui/material";
import ModalTitle from "@pd/components/ModalTitle";
import { modalStyles } from "../constants/styles";

type Props = {
  onClose: () => void;
};
export default function AutopayConfirmed(props: Props) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}
    >
      <Fade in>
        <Stack gap={2} sx={modalStyles}>
          <ModalTitle title="Autopay confirmed" onClose={handleClose} />
          <Stack sx={{ p: 3 }} alignItems="center">
            <Button variant="contained" onClick={handleClose}>
              Done
            </Button>
          </Stack>
        </Stack>
      </Fade>
    </Modal>
  );
}
