import { Theme } from "@mui/material/styles";
import { LinkedAcctType } from "../../types";

export default function getAccountStatus(link: LinkedAcctType, theme: Theme) {
  switch (link.account.verificationStatus) {
    case "not_active":
      return {
        text: "Inactive",
        color: theme.palette.warning.main,
        showRetry: false,
      };
    case "automatically_verified":
      return {
        text: "Active",
        color: theme.palette.success.main,
        showRetry: false,
      };
    case "manually_verified":
      return {
        text: "Active",
        color: theme.palette.success.main,
        showRetry: false,
      };
    case "verification_expired":
      return {
        text: "Retry",
        color: theme.palette.error.main,
        showRetry: true,
      };
    case "verification_failed":
      return {
        text: "Retry",
        color: theme.palette.error.main,
        showRetry: true,
      };
    default:
      if (!link.account.verificationStatus && !link.error?.errorCode) {
        // The account is not using micro-depoosit verification
        // See Plaid Docs: https://plaid.com/docs/link/react-native/#link-react_native-onsuccess-metadata-accounts-verification-status-null
        return {
          text: "Active",
          color: theme.palette.success.main,
          showRetry: false,
        };
      }
      return {
        text: "Retry",
        color: theme.palette.error.main,
        showRetry: true,
      };
  }
}
