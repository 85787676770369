// NOTE: Using safeFetch/types instead of safeFetch/index here to avoid
// a circular dependency from
// redux/types/index -> redux/types/AsyncStateType -> safeFetch/index -> redux/types/index
import { ErrorPayload } from "@pd/api/utils/safeFetch/types";

export interface AsyncStateType {
  success: boolean;
  fetching: boolean;
  error: ErrorPayload;
}

export function isAsyncStateType(value: any): value is AsyncStateType {
  return (
    value &&
    typeof value === "object" &&
    ("fetching" in value ||
      "error" in value ||
      "data" in value ||
      "success" in value)
  );
}
export const createAsyncSlice = <T>(initState: T) => ({
  error: {
    message: "",
    status: 0,
  },
  fetching: false,
  success: false,
  data: initState,
});
