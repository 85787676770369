import { UNAUTHORIZED_ERROR_TYPE } from "./constants";

export default class UnauthorizedApiError extends Error {
  type: string;

  status: number;

  constructor(m?: string) {
    super(m || "unauthorized");
    this.type = UNAUTHORIZED_ERROR_TYPE;
    this.status = 401;
  }
}
